import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import EfficiencyPage from "../efficiency/EfficiencyPage";
import HouseEditor from "../houses/HouseEditor";
import HouseSearch from "../houses/HouseSearch";
import Leaderboard from "../leaderboard/Leaderboard";
import AllTags from "../list/AllTags";
import TagList from "../list/TagList";
import ManualEntries from "../manual/ManualEntries";
import ManagePickup from "../pickup/ManagePickup";
import PickupPage from "../pickup/PickupPage";
import QrCodePage from "../qr-code/QRCodePage";
import MasterQuadList from "../quads/MasterQuadList";
import QuadEditor from "../quads/QuadEditor";
import Quad from "../quads/QuadPage";
import Quads from "../quads/QuadsPage";
import ScoutStats from "../scouts/ScoutStats";
import InstallInstructions from "../settings/InstallInstructions";
import Settings from "../settings/Settings";
import NavBar from "./NavBar";
import URIRedirect from "../settings/URIRedirect";
import MoveResponses from "../settings/MoveResponses";
import ManageSales from "../manage/Manage";
import DriverDashboard from "../dashboard/DriverDashboard";
import { useDispatch } from "react-redux";
import { fetchGoals, fetchHours, fetchHouses, fetchManualEntries, fetchPickupGroups, fetchQuads, fetchResponses, fetchRewardLevels, fetchScoutStats, fetchSiteConfig, fetchTrees, fetchUsers, fetchYearStats } from "../../store/slices";
import { getUserInfo, isAdmin, isDriver } from "../../utils/API";
import ManagePublicSite from "../manage/EditPublicSite";

interface MainPageProps {
  newVersionAvailable: boolean;
  onSWUpdateRequest: () => void;
  theme: "light" | "dark";
  onThemeUpdate: (theme: "light" | "dark") => void;
}

const MainPage: React.FC<MainPageProps> = (props) => {
  const [bottomNavPage, setBottomNavPage] = useState(getPathIndex(window.location.pathname, localStorage.getItem("driver-ui-enabled") === "true"));
  const [isDriverUIEnabled, setIsDriverUIEnabled] = useState(localStorage.getItem("driver-ui-enabled") === "true");
  const dispatch = useDispatch();

  const userInfo = getUserInfo();

  useEffect(() => {
    dispatch(fetchResponses() as any);
    dispatch(fetchHouses() as any);
    dispatch(fetchQuads() as any);
    dispatch(fetchUsers() as any);
    dispatch(fetchHours() as any);
    dispatch(fetchRewardLevels() as any);
    dispatch(fetchGoals() as any);
    dispatch(fetchManualEntries() as any);
    dispatch(fetchPickupGroups() as any);
    dispatch(fetchScoutStats() as any);
    dispatch(fetchYearStats() as any);
    dispatch(fetchTrees() as any);
    dispatch(fetchSiteConfig() as any);
  }, [dispatch]);

  useEffect(() => {
    if (
      (isAdmin(userInfo) || isDriver(userInfo)) &&
      !isDriverUIEnabled &&
      isPickupDay()
    ) {
      toast(
        (t) => (
          <span>
            Enable driver UI?{" "}
            <button className="btn btn-secondary me-1" onClick={() => {
              setIsDriverUIEnabled(true);
              localStorage.setItem("driver-ui-enabled", "true");
              toast.dismiss(t.id);
            }}>Enable</button>
            <button className={"btn btn-sm " + (props.theme === "light" ? "text-light" : "")} onClick={() => toast.dismiss(t.id)}>&times;</button>
          </span>
        ),
        {
          id: "driver-ui-toast",
          duration: Infinity,
          icon: <i className="bi bi-truck"></i>,
        }
      );
    }
  }, [props, isDriverUIEnabled]);

  useEffect(() => {
    if (props.newVersionAvailable) {
      toast(
        (t) => (
          <span>
            New version available.{" "}
            <button className="btn btn-secondary" onClick={() => props.onSWUpdateRequest()}>Update</button>
          </span>
        ),
        {
          id: "update-toast",
          duration: Infinity,
          icon: <i className="bi bi-cloud-arrow-down-fill"></i>,
        }
      );
    }
  }, [props]);

  const savedQuadId = parseInt(localStorage.getItem("quad") || "-1");
  const savedQuad = (isNaN(savedQuadId) || savedQuadId === -1) ? "" : savedQuadId.toString();

  return (
    <BrowserRouter>
      <NavBar theme={props.theme} />
      <div style={{ height: "86vh", overflow: "auto" }}>
        <Routes>
          {isDriverUIEnabled ? (
            <Route path="/" element={<DriverDashboard />} />
          ) : (
            <Route path="/" element={<Dashboard />} />
          )}
          <Route path="/leaderboard" element={<Leaderboard theme={props.theme} />} />
          <Route path="/settings"
            element={
              <Settings
                theme={props.theme}
                onThemeUpdate={props.onThemeUpdate}
                driverUIEnabled={isDriverUIEnabled}
                onDriverUIEnabledChange={
                  (enabled: boolean) => {
                    setIsDriverUIEnabled(enabled);
                    setBottomNavPage(getPathIndex(window.location.pathname, enabled));
                    localStorage.setItem("driver-ui-enabled", enabled.toString());
                  }
                }
              />
            }
          />
          <Route path="/settings/move-responses" element={<MoveResponses />} />
          <Route path="/install" element={<InstallInstructions />} />
          <Route path="/protocol" element={<URIRedirect />} />
          <Route path="/efficiency" element={<EfficiencyPage />} />
          <Route path="/list" element={<TagList theme={props.theme} />} />
          <Route path="/manage/list" element={<AllTags theme={props.theme} />} />
          <Route path="/manual" element={<ManualEntries />} />
          <Route path="/manage/quads" element={<Quads />} />
          <Route path="/manage" element={<ManageSales />} />
          <Route path="/manage/public-site" element={<ManagePublicSite />} />
          <Route path="/manage/quads/map" element={<MasterQuadList theme={props.theme} />} />
          <Route path="/map/sales/:quadId" element={<Quad theme={props.theme} />} />
          <Route path="/map/sales" element={<Quad theme={props.theme} />} />
          <Route path="/manage/pickup" element={<ManagePickup theme={props.theme} />} />
          <Route path="/map/pickup/:quadId" element={<PickupPage theme={props.theme} />} />
          <Route path="/map/pickup" element={<PickupPage theme={props.theme} />} />
          <Route
            path="/quads/:quadId/edit"
            element={<QuadEditor theme={props.theme} />}
          />
          <Route path="/qr-code" element={<QrCodePage />} />
          <Route path="/scouts" element={<ScoutStats />} />
          <Route path="/scouts/:id" element={<Dashboard />} />
          <Route path="/houses" element={<HouseSearch />} />
          <Route path="/houses/:id" element={<HouseEditor theme={props.theme} />} />
        </Routes>
      </div>
      {!isDriverUIEnabled ? (
        <BottomNavigation
          showLabels
          value={bottomNavPage}
          onChange={(event, newValue) => {
            setBottomNavPage(newValue);
          }}
          className="fixed-bottom"
        >
          <BottomNavigationAction component={Link} to="/" label="Dashboard" icon={<i className="bi bi-house-door-fill"></i>} />
          <BottomNavigationAction component={Link} to="/leaderboard" label="Leaderboard" icon={<i className="bi bi-award-fill"></i>} />
          <BottomNavigationAction component={Link} to={`/map/sales/${savedQuad}`} label="Map" icon={<i className="bi bi-map"></i>} />
          <BottomNavigationAction component={Link} to="/qr-code" label="QR Code" icon={<i className="bi bi-qr-code-scan"></i>} />
        </BottomNavigation>
      ) : (
        <BottomNavigation
          showLabels
          value={bottomNavPage}
          onChange={(event, newValue) => {
            setBottomNavPage(newValue);
          }}
          className="fixed-bottom"
        >
          <BottomNavigationAction component={Link} to="/" label="Dashboard" icon={<i className="bi bi-house-door-fill"></i>} />
          <BottomNavigationAction component={Link} to="/map/pickup" label="Map" icon={<i className="bi bi-map"></i>} />
          <BottomNavigationAction component={Link} to="/settings" label="Settings" icon={<i className="bi bi-gear-fill"></i>} />
        </BottomNavigation>
      )}
      <LocationListener onLocationChange={(path) => {
        const bottomNavPage = getPathIndex(path, isDriverUIEnabled);
        setBottomNavPage(bottomNavPage);
      }} />
    </BrowserRouter>
  );
};

interface LocationListenerProps {
  onLocationChange: (location: string) => void;
}

function LocationListener(props: LocationListenerProps) {
  const location = useLocation();
  useEffect(() => {
    props.onLocationChange(location.pathname);
  }, [location, props]);
  return null;
}

function getPathIndex(path: string, isDriverUIEnabled: boolean): number {
  if (!isDriverUIEnabled) {
    if (path.startsWith("/map/sales")) {
      return 2;
    }
    switch (path) {
      case "/":
        return 0;
      case "/leaderboard":
        return 1;
      case "/qr-code":
        return 3;
      default:
        return -1;
    }
  } else {
    if (path.startsWith("/map/pickup")) {
      return 1;
    }
    switch (path) {
      case "/":
        return 0;
      case "/settings":
        return 2;
      default:
        return -1;
    }
  }
}

function isPickupDay() {
  return new Date().getDay() === 6 &&
    new Date().getMonth() === 0 &&
    new Date().getDate() < 9 &&
    new Date().getDate() > 1;
}

export default MainPage;
