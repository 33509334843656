import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DrawPolygon from "./DrawPolygon";
import UserTagify from "./UserTagify";
import { TreeTagQuad } from "../../store/types";
import { useSelector } from "react-redux";

interface QuadEditorProps {
  theme: "light" | "dark";
}

const QuadEditor: React.FC<QuadEditorProps> = ({ theme }) => {
  const { quadId } = useParams();
  const [assignedScouts, setAssignedScouts] = useState<number[]>([]);
  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);
  const quad = quads.find((quad: TreeTagQuad) => quad.id === Number(quadId));
  const [polygon, setPolygon] = useState<any>(null);

  useEffect(() => {
    if (quad) {
      setAssignedScouts(quad.assigned_to);
      setPolygon(quad.polygon);
    }
  }, [quad]);

  const updateQuad = () => {
    // TODO: Update quad
  };

  return (
    <div className="container">
      <h1>Quad #{quadId}</h1>
      <div className="row">
        <div className="col-12">
          <label htmlFor="assigned_scouts_edit">Assigned Scouts: </label>
          <UserTagify
            defaultValue={assignedScouts}
            onChange={setAssignedScouts}
          />
        </div>
        <div className="col-12">
          <DrawPolygon
            theme={theme}
            polygon={polygon}
            quad_id={Number(quadId)}
            onChange={setPolygon}
          />
        </div>
      </div>
      <button
        className="btn btn-primary"
        onClick={updateQuad}
      >
        Save
      </button>
    </div>
  );
};

export default QuadEditor;
