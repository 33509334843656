import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../quads/QuadMap.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useRef } from "react";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import { useSelector } from "react-redux";
import { TreeTagHouse, TreeTagPickupGroup } from "../../store/types";

interface SelectPickupGroupProps {
  theme: "light" | "dark";
  onGroupSelect(group_id: number): void;
  center?: [number, number];
}

const SelectPickupGroup: React.FC<SelectPickupGroupProps> = ({ theme, onGroupSelect, center }) => {
  const mapRef = useRef<any>(null);

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const pickupGroups = useSelector((state: any) => state.pickupGroups.data as TreeTagPickupGroup[]);
  
  const pickupGroupsGeoJSON: GeoJSON.Feature<GeoJSON.Geometry>[] = pickupGroups
    .filter((group) => group.name !== "Quad #0")
    .map((q) => {
      const housesInGroup = houses.filter((h) => h.quad_id === q.id);
      const minLat = Math.min(...housesInGroup.map((house) => house.lat));
      const maxLat = Math.max(...housesInGroup.map((house) => house.lat));
      const minLng = Math.min(...housesInGroup.map((house) => house.lng));
      const maxLng = Math.max(...housesInGroup.map((house) => house.lng));
      const BUFFER_SIZE = 0.0004;
      return {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [minLat - BUFFER_SIZE, minLng - BUFFER_SIZE],
              [minLat - BUFFER_SIZE, maxLng + BUFFER_SIZE],
              [maxLat + BUFFER_SIZE, maxLng + BUFFER_SIZE],
              [maxLat + BUFFER_SIZE, minLng - BUFFER_SIZE],
              [minLat - BUFFER_SIZE, minLng - BUFFER_SIZE],
            ],
          ],
        },
        properties: {
          id: q.id,
          name: q.name,
          is_assigned: q.assigned_to > 0,
        },
      };
    });

  return (
    <div className="h-100">
      <div className="map h-100">
        <MapProvider>
          <Map
            id="quads-map"
            ref={(ref) => {
              if (ref && !mapRef.current) {
                mapRef.current = ref.getMap();
                if (!mapRef.current._controls.some((control: any) => control.modes)) {
                  mapRef.current.on("idle", () => {
                    mapRef.current.resize();
                  });
                  // on click, get quad_id of clicked quad
                  mapRef.current.on("click", (e: any) => {
                    const features = mapRef.current.queryRenderedFeatures(e.point);
                    for (const feature of features) {
                      if (feature.source.startsWith("quad")) {
                        onGroupSelect(feature.properties.id);
                      }
                    }
                  });
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: center ? center[0] : -122.21,
              latitude: center ? center[1] : 47.63,
              zoom: center ? 15 : 12,
            }}
            mapStyle={theme === "light" ?
              "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
              "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
            maxBounds={MAP_BOUNDS}
          >
            {pickupGroupsGeoJSON.map((group, i) => (
              <Source key={"group_border_" + i} id={"group" + i} type="geojson" data={group}>
                <Layer
                  id={"quad-" + i}
                  type="fill"
                  paint={{
                    "fill-color": ["case", ["get", "is_assigned"], "#0000ff", "#00ff00"],
                    "fill-opacity": 0.2,
                    "fill-outline-color": "#000000",
                  }}
                />
                <Layer
                  id={"group-label-" + i}
                  type="symbol"
                  layout={{
                    "text-field": ["get", "name"],
                    "text-size": 12,
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  }}
                  paint={{
                    "text-color": "#000000",
                  }}
                />
              </Source>
            ))}
          </Map>
        </MapProvider>
      </div>
    </div>
  );
};

export default SelectPickupGroup;
