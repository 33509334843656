import axios from "axios";
import { API_URL } from "./Params";

export function getUserInfo() {
  const jwt = localStorage.getItem("jwt");
  if (!jwt) {
    return null;
  }
  const base64Url = jwt.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  return payload;
}

export function isAdmin(userInfo: any) {
  return userInfo.roles.includes("Admin") || userInfo.roles.includes("TreeTagsAdmin");
}

export function isDriver(userInfo: any) {
  return userInfo.roles.includes("TreeTagsDriver");
}

export function getSeason(date: Date) {
  let year = date.getFullYear();
  let month = date.getMonth();
  if (month < 6) {
    year--;
  }
  return year;
}

export function callAPI(endpoint: string, method = "GET", data?: any) {
  const jwt = localStorage.getItem("jwt");
  return axios({
    method,
    url: `${API_URL}/${endpoint}`,
    data,
    headers: {
      Authorization: jwt,
    },
  });
}
