import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/Formatter";
import { useSelector } from "react-redux";
import { TreeTagHouse, TreeTagResponse } from "../../store/types";
import { useEffect, useState } from "react";
import { callAPI, getUserInfo, isAdmin } from "../../utils/API";
import UserTagify from "./UserTagify";
import { interpretResponse } from "../list/TagList";

interface HouseInfoProps {
  theme: "light" | "dark";
  houseId: number;
  onClose: () => void;
}

const userInfo = getUserInfo();

export const canDeleteResponse = (response: TreeTagResponse) => {
  return isAdmin(userInfo) || parseInt(userInfo.sub) === response.created_by;
}

const HouseInfo: React.FC<HouseInfoProps> = ({ theme, houseId, onClose }) => {
  const defaultScout = localStorage.getItem("report-as") || userInfo.sub;
  const [name, setName] = useState("");
  const [money, setMoney] = useState(0);
  const [numTags, setNumTags] = useState(0);
  const [result, setResult] = useState(0);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [scout, setScout] = useState(parseInt(defaultScout));
  const [salesNote, setSalesNote] = useState("");
  const [pickupNote, setPickupNote] = useState("");
  const [responseHistory, setResponseHistory] = useState<TreeTagResponse[]>([]);

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const house = houses.find((h) => h.id === houseId);

  useEffect(() => {
    if (house) {
      callAPI(`private/tables/tree_tag_response?house_id=${houseId}`)
        .then((data) => {
          setResponseHistory(data as unknown as TreeTagResponse[]);
        });
    }
  }, [houseId]);

  const saveResponse = () => {
    if (!house) { return; }
    const response: TreeTagResponse = {
      house_id: house.id,
      quad_id: house.quad_id,
      money, name, phone, email, scout,
      num_tags: numTags,
      sales_note: salesNote,
      response: result,

      // Other fields
      id: new Date().getTime(),
      picked_up_status: 0,
      pickup_note: "",
      pickup_group_id: 0,
      stripe_session_id: "",
      created_at: 0,
      created_by: 0
    };
    // TODO: Save response
    console.log(response);
  };

  const deleteResponse = (id: number) => {
    if (window.confirm("Are you sure you want to delete this response?")) {
      // TODO: Delete response
    }
  };

  useEffect(() => {
    localStorage.setItem("report-as", scout.toString());
  }, [scout]);

  if (!house) {
    return <div>House not loaded</div>;
  }
  if (!house.address) {
    console.log(house);
    return <div>House does not have an address</div>;
  }
  const shortAddress = shortenAddress(house.address.split(",")[0]);

  return (
    <div className="mb-5 mt-2 pb-5" style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <div className="overlay-header text-center my-3">
        <h3>{shortAddress.toUpperCase()}</h3>
      </div>
      <div className="overlay-body container">
        <Card variant="outlined" className="p-3">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="addr_result">Result</label>
                <select
                  className="form-select"
                  id="addr_result"
                  value={result}
                  onChange={(e) => { setResult(parseInt(e.target.value)) }}
                >
                  <option value="0">Tree Tag</option>
                  <option value="1">Donation</option>
                  <option value="2">Refused</option>
                  <option value="3">No Answer</option>
                  <option value="4">Other - See Comment</option>
                </select>
              </div>
            </div>
            {result <= 1 && (
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="addr_name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            )}
            {result <= 1 && (
              <div className="col-md-3 col-sm-6" id="money_input">
                <div className="form-group">
                  <label htmlFor="addr_money">Money Collected</label>
                  <input
                    type="number"
                    className="form-control"
                    id="addr_money"
                    placeholder="$0.00"
                    value={money}
                    onChange={(e) => setMoney(parseFloat(e.target.value))}
                  />
                </div>
              </div>
            )}
            {result === 0 && (
              <div className="col-md-3 col-sm-6" id="tags_input">
                <div className="form-group">
                  <label htmlFor="addr_tags">Tree Tags Sold</label>
                  <input
                    type="number"
                    className="form-control"
                    id="addr_tags"
                    placeholder="0"
                    value={numTags}
                    onChange={(e) => setNumTags(parseInt(e.target.value))}
                  />
                </div>
              </div>
            )}
            {result <= 1 && (
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="addr_email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            )}
            {result <= 1 && (
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="addr_phone">Phone</label>
                  <input
                    type="phone"
                    className="form-control"
                    id="addr_phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="addr_sales_note">Give credit for donation to</label>
                <UserTagify
                  defaultValue={[scout]}
                  onChange={(ids) => ids.length > 0 && setScout(ids[0])} />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="addr_sales_note">Sales Note</label>
                <textarea
                  className="form-control"
                  id="addr_sales_note"
                  value={salesNote}
                  onChange={(e) => setSalesNote(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="addr_pickup_note">Pickup Note</label>
                <textarea
                  className="form-control"
                  id="addr_pickup_note"
                  value={pickupNote}
                  onChange={(e) => setPickupNote(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2 w-xs-100"
                  onClick={saveResponse}
                >
                  Save response
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2 w-xs-100"
                  onClick={onClose}
                >
                  Cancel
                </button>
                {isAdmin(userInfo) && (
                  <Link
                    className="btn btn-outline-warning mt-2 me-2 w-xs-100"
                    to={"/houses/" + house.id}
                  >
                    Edit house
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Card>
        <hr />
        <h2>History</h2>
        <div style={{ width: "auto", overflow: "auto" }}>
          <table className={"table " + (theme === "dark" && "text-white")}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Result</th>
                <th>Money</th>
                <th># Sold</th>
                <th>Sold By</th>
                <th>Notes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {responseHistory.map((response) => {
                const date = new Date(response.created_at * 1000);
                return (
                  <tr key={"response_" + response.id}>
                    <td>{date.toLocaleString()}</td>
                    <td>{interpretResponse(response.response)}</td>
                    <td>{formatter.format(response.money)}</td>
                    <td>{response.num_tags}</td>
                    <td>{response.scout + (response.stripe_session_id ? " (online sale)" : "")}</td>
                    <td>{response.sales_note}</td>
                    <td>
                      {canDeleteResponse(response) ? (
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => deleteResponse(response.id)}
                        >
                          Delete
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export function shortenAddress(address: string) {
  const replacements = [
    ["Avenue", "Ave"],
    ["Street", "St"],
    ["Road", "Rd"],
    ["Drive", "Dr"],
    ["Court", "Ct"],
    ["Lane", "Ln"],
    ["Circle", "Cir"],
    ["Boulevard", "Blvd"],
    ["Terrace", "Ter"],
    ["Parkway", "Pkwy"],
    ["Place", "Pl"],
    ["Way", "Wy"],
    ["Highway", "Hwy"],
    ["Northwest", "NW"],
    ["Northeast", "NE"],
    ["Southwest", "SW"],
    ["Southeast", "SE"],
    ["North", "N"],
    ["South", "S"],
    ["East", "E"],
    ["West", "W"],
  ];
  let shortAddress = address;
  for (const [long, short] of replacements) {
    shortAddress = shortAddress.replace(new RegExp(long + "(?= |$)", "g"), short);
  }
  return shortAddress;
}

export default HouseInfo;
