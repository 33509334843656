import { useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import SelectPickupGroup from "./SelectPickupGroup";
import { TreeTagHouse, TreeTagResponse } from "../../store/types";
import { useSelector } from "react-redux";

interface MoveTagToGroupModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  tag: TreeTagResponse;
  theme: "light" | "dark";
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const MoveTagToGroupModal: React.FC<MoveTagToGroupModalProps> = ({
  open,
  onClose,
  onUpdate,
  tag,
  theme,
}) => {
  const [otherId, setOtherId] = useState<number>(-1);

  const save = () => {
    // TODO: Update pickup group id for response
  };

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);

  const house = houses.find((house) => house.id === tag.house_id);

  if (!house) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addMoveTagToGroupModalLabel">
              Move Tag to Group
            </h4>
          </div>
          <div className="modal-body" style={{ height: "50vh" }}>
            <SelectPickupGroup
              theme={theme}
              onGroupSelect={(id) => {
                setOtherId(id);
              }}
              center={[house.lat, house.lng]}
            />
            <div className="mt-3">
              <b>Current Group: #{tag.pickup_group_id}</b><br />
              {otherId !== -1 ? (
                <b>Selected Group: #{otherId}</b>
              ) : (
                <b>No Group Selected</b>
              )}
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
              disabled={otherId === -1}
            >
              Move
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MoveTagToGroupModal;
