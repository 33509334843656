import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import PickupGroupCard from "./PickupGroupCard";
import { TreeTagPickupGroup, TreeTagResponse } from "../../store/types";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/API";

const userInfo = getUserInfo();

const DriverDashboard: React.FC = () => {
  const assignedTrees = useSelector((state: any) => state.assignedTrees.data as TreeTagResponse[]);
  const pickupGroups = useSelector((state: any) => state.pickupGroups.data as TreeTagPickupGroup[]);
  const assignedGroups = pickupGroups.filter((group) => group.assigned_to === parseInt(userInfo.sub));

  const numAssigned = assignedTrees.reduce((acc, tree) => {
    return tree.num_tags + acc;
  }, 0);
  const numIncompleteTrees = assignedTrees.filter((tree) => tree.picked_up_status === 0)
    .reduce((acc, tree) => {
      return tree.num_tags + acc;
    }, 0);
  const numPickedUpTrees = assignedTrees.filter((tree) => tree.picked_up_status === 1)
    .reduce((acc, tree) => {
      return tree.num_tags + acc;
    }, 0);

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-6 col-sm-12 row">
              <div className="col-lg-6 col-sm-12">
                <h2>Your Stats</h2>
                <h4>Trees Assigned: {numAssigned}</h4>
                <h4>Trees Picked Up: {numPickedUpTrees}</h4>
                <h4>Trees Skipped: {numAssigned - numPickedUpTrees - numIncompleteTrees}</h4>
                <hr />
                <h4>Trees Left: {numIncompleteTrees}</h4>
              </div>
            </div>
          </div>
          <Card variant="outlined" className="mt-2 p-3">
            <Link to="/list" className="btn btn-outline-primary me-2 mt-2">
              Enter Tags
            </Link>
            <hr />
            <h3>Your Pickup Groups</h3>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {assignedGroups.map((group) => (
                <PickupGroupCard key={group.id} group={group} />
              ))}
              {assignedGroups.length === 0 && (
                <p className="text-muted">
                  You are not assigned to any groups. Head to the map to claim a group.
                </p>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DriverDashboard;
