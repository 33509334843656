import { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./NavBar.css";
import toast from "react-hot-toast";
import { getUserInfo } from "../../utils/API";

interface NavBarProps {
  theme: "light" | "dark";
}

interface NavBarState {
  currentPage: string;
  pwaStatus: PWAStatus;
  deferredPrompt: any;
}

enum PWAStatus {
  INSTALLED,
  NOT_INSTALLED,
  IS_PWA,
  NOT_SUPPORTED,
}

class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.state = {
      currentPage: window.location.pathname,
      pwaStatus: PWAStatus.NOT_SUPPORTED,
      deferredPrompt: null,
    };
  }

  componentDidMount() {
    this.checkPWAStatus();
  }

  async checkPWAStatus() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.setState({ deferredPrompt: e, pwaStatus: PWAStatus.NOT_INSTALLED });
    });
    const isPWA = window.matchMedia("(display-mode: standalone)").matches;
    let pwaInstalled = false;
    if ('getInstalledRelatedApps' in window.navigator) {
      // @ts-ignore
      const relatedApps = await navigator.getInstalledRelatedApps();
      relatedApps.forEach((app: any) => {
        pwaInstalled = true;
      });
    }
    if (isPWA) {
      this.setState({ pwaStatus: PWAStatus.IS_PWA });
    } else if (pwaInstalled) {
      console.log("pwaInstalled");
      this.setState({ pwaStatus: PWAStatus.INSTALLED });
    }
  }

  installPWA() {
    if (this.state.deferredPrompt === null) {
      toast.error("Could not install app", { id: "error" });
      return;
    }
    // @ts-ignore
    this.state.deferredPrompt.prompt();
    // @ts-ignore
    this.state.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        toast.success("Installing app...", { id: "error" });
        setTimeout(() => {
          this.checkPWAStatus();
        }, 1000);
      }
      // @ts-ignore
      window.deferredPrompt = null;
    });
  }

  openPWA() {
    // @ts-ignore
    window.open(window.location.href, "_self");
  }

  render() {
    let pwaButton = null;
    if (this.state.pwaStatus === PWAStatus.NOT_INSTALLED) {
      pwaButton = (
        <button className="dropdown-item" onClick={() => this.installPWA()}>
          Install App
        </button>
      );
    } else if (this.state.pwaStatus === PWAStatus.INSTALLED) {
      pwaButton = (
        <button className="dropdown-item" onClick={() => this.openPWA()}>
          Open App
        </button>
      );
    } else if (this.state.pwaStatus === PWAStatus.NOT_SUPPORTED) {
      pwaButton = (
        <Link className="dropdown-item" to="/install">
          Install App
        </Link>
      );
    }

    const userInfo = getUserInfo();

    const mainMenu = [
      { name: "Manage Quads", link: "/manage/quads", for: ["Admin"] },
      {
        name: "Manage Pickup",
        link: "/manage/pickup",
        for: ["Admin"],
      },
      {
        name: "Scout Stats",
        link: "/scouts",
        for: ["Admin"],
      },
      {
        name: "Manage Sales",
        link: "/manage",
        for: ["Admin"],
      },
      { name: "Your Tags", link: "/list", for: ["TreeTagsScout"] },
      { name: "All Tags", link: "/manage/list", for: ["Admin"] },
      { name: "Pickup Map", link: "/map/pickup", for: ["TreeTagsDriver"] },
    ];
    return (
      <header className="py-2 border-bottom no-print">
        <div className="container-fluid d-grid gap-3 align-items-center custom-grid-template-columns">
          <div className="dropdown">
            <Link
              to="/"
              className={"d-flex align-items-center col-lg-4 mb-lg-0 text-decoration-none dropdown-toggle" +
                (this.props.theme === "dark" ? " link-light" : " link-dark")}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-tree-fill fs-4 mb-2 mt-0"></i>
              <h1 className="fs-5 ms-1">Tree Tags</h1>
            </Link>
            <ul className="dropdown-menu text-small shadow">
              {mainMenu.map((menuItem) => {
                if (menuItem.for.some((r)=>userInfo.roles.includes(r))) {
                  return null;
                }
                return (
                  <li key={menuItem.name}>
                    <Link
                      to={menuItem.link}
                      className="dropdown-item"
                      onClick={() => {
                        this.setState({ currentPage: menuItem.link });
                      }}
                    >
                      {menuItem.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="d-flex align-items-center">
            <form className="w-100 me-3" role="search">
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                aria-label="Search"
                style={{ opacity: 0 }}
              />
            </form>
            <div className="flex-shrink-0 dropdown">
              <Link
                to="/account"
                className={"d-block text-decoration-none dropdown-toggle" +
                  (this.props.theme === "dark" ? " link-light" : " link-dark")}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                  alt="mdo"
                  width="26"
                  height="26"
                  className="rounded-circle"
                />
              </Link>
              <ul className="dropdown-menu text-small shadow">
                {pwaButton !== null && (
                  <li>
                    {pwaButton}
                  </li>
                )}
                {pwaButton !== null && (
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                )}
                <li>
                  <Link className="dropdown-item" to="/settings">
                    Settings
                  </Link>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      const theme = localStorage.getItem("theme");
                      localStorage.clear();
                      localStorage.setItem("theme", theme || "light");
                      window.location.reload();
                    }}
                  >
                    Sign out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header >
    );
  }
}

export default NavBar;
