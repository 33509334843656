import { Alert, Card } from "@mui/material";
import { useSelector } from "react-redux";
import { formatter } from "../../utils/Formatter";
import { useState } from "react";
import EditFAQModal from "./public-site-modals/EditFAQModal";
import EditProductModal from "./public-site-modals/EditProductModal";
import EditPickupModal from "./public-site-modals/EditPickupModal";
import { callAPI } from "../../utils/API";

const ManagePublicSite: React.FC = () => {
  const [editFaqModalOpen, setEditFaqModalOpen] = useState(false);
  const [editTreeTagModalOpen, setEditTreeTagModalOpen] = useState(false);
  const [editDonationModalOpen, setEditDonationModalOpen] = useState(false);
  const [editPickupModalOpen, setEditPickupModalOpen] = useState(false);

  const config = useSelector((state: any) => state.siteConfig?.data);
  const isFetching = useSelector((state: any) => state.siteConfig?.isFetching);
  if (isFetching) {
    return (
      <div className="container mt-3">
        <h2>
          Edit Public Site
        </h2>
        <p>Loading...</p>
      </div>
    );
  }
  if (!config) {
    return (
      <div className="container mt-3">
        <h2>
          Edit Public Site
        </h2>
        <Alert severity="error">
          Error loading site config.
        </Alert>
      </div>
    );
  }
  const pickupConfig = config.find((entry: any) => entry.name === 'pickup');
  const pickupDate = new Date(pickupConfig?.data?.date).toLocaleDateString('en-US', { timeZone: 'UTC' });
  const dropoffAllowed = pickupConfig?.data?.dropoffAllowed || false;
  const dropoffLocation = pickupConfig?.data?.dropoffLocation || '';
  const dropoffHours = pickupConfig?.data?.dropoffHours || '';
  const pickupZipCodes = pickupConfig?.data?.zipCodes || [];

  const treeTagConfig = config.find((entry: any) => entry.name === 'tree_tag');
  const tagPrice = treeTagConfig?.data?.suggestedDonation;
  const tagDescription = treeTagConfig?.data?.description;
  const tagStripeUrl = treeTagConfig?.data?.stripeUrl;
  const tagMultipleStripeUrl = treeTagConfig?.data?.stripeUrlMultiple;

  const donationConfig = config.find((entry: any) => entry.name === 'donation');
  const donationPrice = donationConfig?.data?.suggestedDonation;
  const donationDescription = donationConfig?.data?.description;
  const donationStripeUrl = donationConfig?.data?.stripeUrl;

  const faqConfig = config.find((entry: any) => entry.name === 'faq');
  const faqQuestions = faqConfig?.data?.questions || [];

  const updateData = (name: string, data: any) => {
    callAPI('private/custom/set-site-params-public', "POST", { name, data });
    // TODO: Update the redux store with the new data
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h2>
            Edit Public Site
          </h2>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 mt-3">
        <div className="col">
          <Card className="p-3 h-100">
            <h3>Pickup Settings</h3>
            <h1 className="display-4">
              {pickupDate}
            </h1>
            <p className="text-muted mb-0">
              Allowed Zip Codes: {pickupZipCodes.join(", ")}
            </p>
            <p className="text-muted mb-0">
              Dropoff Allowed: {dropoffAllowed ? "Yes" : "No"}
            </p>
            <p className="text-muted mb-0">
              Dropoff Location: {dropoffLocation}
            </p>
            <p className="text-muted mb-0">
              Dropoff Hours: Between {dropoffHours}
            </p>
            <p className="text-muted mb-0">Map Settings</p>
            <hr />
            <button className="btn btn-sm btn-outline-secondary float-end"
              onClick={() => setEditPickupModalOpen(true)}
            >Edit</button>
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 h-100">
            <h3>FAQ</h3>
            <p className="text-muted mb-0">
              {faqQuestions.length} Questions
            </p>
            <hr />
            <button
              className="btn btn-sm btn-outline-secondary float-end"
              onClick={() => setEditFaqModalOpen(true)}
            >Edit</button>
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 h-100">
            <h3>Tree Tag</h3>
            <p className="text-muted mb-0">
              Suggested Donation: {formatter.format(tagPrice)}
            </p>
            <p className="text-muted mb-0">
              Description: {tagDescription.substring(0, 25)}...
            </p>
            <p className="text-muted mb-0">
              Stripe URL: {tagStripeUrl ? "Present" : "Not Present"}
            </p>
            <p className="text-muted mb-0">
              QTY Enabled Stripe URL: {tagMultipleStripeUrl ? "Present" : "Not Present"}
            </p>
            <hr />
            <button
              className="btn btn-sm btn-outline-secondary float-end"
              onClick={() => setEditTreeTagModalOpen(true)}
            >Edit</button>
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 h-100">
            <h3>Donation</h3>
            <p className="text-muted mb-0">
              Suggested Donation: {formatter.format(donationPrice)}
            </p>
            <p className="text-muted mb-0">
              Description: {donationDescription.substring(0, 25)}...
            </p>
            <p className="text-muted mb-0">
              Stripe URL: {donationStripeUrl ? "Present" : "Not Present"}
            </p>
            <hr />
            <button
              className="btn btn-sm btn-outline-secondary float-end"
              onClick={() => setEditDonationModalOpen(true)}
            >Edit</button>
          </Card>
        </div>
      </div>
      <EditFAQModal
        show={editFaqModalOpen}
        handleClose={() => setEditFaqModalOpen(false)}
        faqs={faqQuestions}
        saveFAQs={(faqList) => {
          updateData('faq', { questions: faqList });
        }}
      />
      <EditProductModal
        show={editTreeTagModalOpen}
        handleClose={() => setEditTreeTagModalOpen(false)}
        productName="Tree Tag"
        expectQty
        productData={treeTagConfig?.data}
        saveProduct={(productData) => {
          updateData('tree_tag', productData);
        }}
      />
      <EditProductModal
        show={editDonationModalOpen}
        handleClose={() => setEditDonationModalOpen(false)}
        productName="Donation"
        productData={donationConfig?.data}
        saveProduct={(productData) => {
          updateData('donation', productData);
        }}
      />
      <EditPickupModal
        show={editPickupModalOpen}
        handleClose={() => setEditPickupModalOpen(false)}
        pickupData={pickupConfig?.data}
        savePickup={(pickupData) => {
          updateData('pickup', pickupData);
        }}
      />
    </div >
  );
};

export default ManagePublicSite;
