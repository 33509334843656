import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import { useSelector } from "react-redux";
import { TreeTagQuad } from "../../store/types";

interface MasterQuadListProps {
  theme: "light" | "dark";
}

const MasterQuadList: React.FC<MasterQuadListProps> = ({ theme }) => {
  const [selectedQuad, setSelectedQuad] = useState<number | null>(null);
  const mapRef = useRef<any>(null);

  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);
  const quadsGeoJson: GeoJSON.Feature<GeoJSON.Geometry>[] = quads.map((q) => {
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [q.polygon],
      },
      properties: {
        quad_id: q.id,
        is_assigned: q.assigned_to.length > 2,
      },
    };
  });

  if (selectedQuad !== null) {
    return <Navigate to={`/quads/${selectedQuad}/edit`} />;
  }

  return (
    <div className="h-100">
      <div className="map h-100">
        <MapProvider>
          <Map
            id="quads-map"
            ref={(ref) => {
              if (ref && !mapRef.current) {
                mapRef.current = ref.getMap();
                if (!mapRef.current._controls.some((control: any) => control.modes)) {
                  mapRef.current.on("idle", () => {
                    mapRef.current.resize();
                  });
                  // on click, get quad_id of clicked quad
                  mapRef.current.on("click", (e: any) => {
                    const features = mapRef.current.queryRenderedFeatures(e.point);
                    for (const feature of features) {
                      if (feature.source.startsWith("quad")) {
                        setSelectedQuad(feature.properties.quad_id);
                      }
                    }
                  });
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: -122.21,
              latitude: 47.63,
              zoom: 13,
            }}
            mapStyle={
              theme === "light"
                ? "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr"
                : "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"
            }
            maxBounds={MAP_BOUNDS}
          >
            {quadsGeoJson.map((quad, i) => (
              <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                <Layer
                  id={"quad-" + i}
                  type="fill"
                  paint={{
                    "fill-color": ["case", ["get", "is_assigned"], "#0000ff", "#00ff00"],
                    "fill-opacity": 0.2,
                    "fill-outline-color": "#000000",
                  }}
                />
                <Layer
                  id={"quad-label-" + i}
                  type="symbol"
                  layout={{
                    "text-field": ["get", "quad_id"],
                    "text-size": 12,
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  }}
                  paint={{
                    "text-color": "#000000",
                  }}
                />
              </Source>
            ))}
          </Map>
        </MapProvider>
      </div>
    </div>
  );
};

export default MasterQuadList;
