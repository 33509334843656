import React, { useState, useEffect } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import "./UserTagify.css";
import { useSelector } from "react-redux";
import { User } from "../../store/types";

function tagTemplate(this: any, tagData: any) {
  return `
            <tag title="${tagData.title || tagData.email}"
                    contenteditable='false'
                    spellcheck='false'
                    tabIndex="-1"
                    class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
                    ${this.getAttributes(tagData)}>
                <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
                <div>
                    <div class='tagify__tag__avatar-wrap'>
                        <img onerror="this.style.visibility='hidden'" src="${tagData.avatar}">
                    </div>
                    <span class='tagify__tag-text'>${tagData.name}</span>
                </div>
            </tag>
        `;
}

function suggestionItemTemplate(this: any, tagData: any) {
  return `
            <div ${this.getAttributes(tagData)}
                class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'
                tabindex="0"
                role="option">
                ${tagData.avatar
      ? `
                <div class='tagify__dropdown__item__avatar-wrap'>
                    <img onerror="this.style.visibility='hidden'" src="${tagData.avatar}">
                </div>`
      : ""
    }
                <strong>${tagData.name}</strong>
                <span>${tagData.email}</span>
            </div>
        `;
}

interface UserTagifyProps {
  defaultValue?: number[];
  onChange?: (ids: number[]) => void;
  placeholder?: string;
  maxTags?: number;
}

interface UserTag {
  avatar: string;
  email: string;
  name: string;
  type: string;
  value: string;
}

const UserTagify: React.FC<UserTagifyProps> = ({ defaultValue, onChange, placeholder, maxTags }) => {
  const [options, setOptions] = useState<UserTag[]>([]);
  const [value, setValue] = useState<UserTag[]>([]);

  const users = useSelector((state: any) => state.users.data as User[]);

  useEffect(() => {
    setOptions(users.map((user: User) => ({
      avatar: "user.avatar", // TODO
      email: user.email,
      name: user.name,
      type: "user",
      value: user.id.toString(),
    })));
  }, [users]);

  useEffect(() => {
    let defaultVal = defaultValue || [];
    setValue(options.filter((user) => defaultVal.map(val => parseInt(String(val), 10)).includes(Number(user.value))));
  }, [defaultValue, options]);

  return (
    <Tags
      settings={{
        tagTextProp: "name",
        enforceWhitelist: true,
        skipInvalid: true,
        dropdown: {
          closeOnSelect: false,
          enabled: 0,
          classname: "users-list",
          searchKeys: ["name", "email"],
        },
        templates: {
          tag: tagTemplate,
          dropdownItem: suggestionItemTemplate,
        },
        maxTags,
      }}
      whitelist={options}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        if (!onChange) return;
        try {
          let value = JSON.parse(e.detail.value);
          onChange(value.map((user: UserTag) => Number(user.value)));
        } catch (e) {
          onChange([]);
        }
      }}
    />
  );
};

export default UserTagify;
