import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/Formatter";
import Card from "@mui/material/Card";
import { TreeTagHouse, TreeTagQuad, User } from "../../store/types";
import { useSelector } from "react-redux";

interface QuadCardProps {
  quad: TreeTagQuad;
  editable?: boolean;
}

function dateInSeason(timestamp: number, seasonOffset: number) {
  const date = new Date(timestamp * 1000);
  const month = date.getMonth();
  return month >= 8 + seasonOffset && month <= 11 + seasonOffset;
}

const QuadCard: React.FC<QuadCardProps> = ({ quad, editable }) => {
  const [money, setMoney] = React.useState(0);
  const [moneyLastYear, setMoneyLastYear] = React.useState(0);
  const [moneyByScout, setMoneyByScout] = React.useState<{ [key: number]: number }>({});
  const [numHouses, setNumHouses] = React.useState(0);
  const [numVisitedHouses, setNumVisitedHouses] = React.useState(0);

  const responses = useSelector((state: any) => state.responses.data);
  const houses = useSelector((state: any) => state.houses.data);
  const users = useSelector((state: any) => state.users.data);

  useEffect(() => {
    if (houses.length === 0) return;
    const quadHouses = houses.filter((house: TreeTagHouse) => house.quad_id === quad.id);
    setNumHouses(quadHouses.length);
  }, [houses, quad.id]);

  useEffect(() => {
    if (responses.length === 0) return;
    const quadResponses = responses.filter((response: any) => response.quad_id === quad.id);
    const quadResponsesThisYear = quadResponses.filter((response: any) => dateInSeason(response.created_at, 0));
    const quadResponsesLastYear = quadResponses.filter((response: any) => dateInSeason(response.created_at, -1));
    setNumVisitedHouses(quadResponsesThisYear.length);
    const money = quadResponsesThisYear.reduce((acc: number, response: any) => acc + response.money, 0);
    setMoney(money);
    const moneyLastYear = quadResponsesLastYear.reduce((acc: number, response: any) => acc + response.money, 0);
    setMoneyLastYear(moneyLastYear);
    const moneyByScout: { [key: number]: number } = {};
    quadResponsesThisYear.forEach((response: any) => {
      if (!moneyByScout[response.scout]) moneyByScout[response.scout] = 0;
      moneyByScout[response.scout] += response.money;
    });
    setMoneyByScout(moneyByScout);
  }, [responses, quad.id]);

  return (
    <div className="col">
      <Card variant="outlined" className="m-2 p-3">
        <h5 className="card-title">{"Quad #" + quad.id}</h5>
        {editable && quad?.assigned_to?.join && quad.assigned_to.length > 0 && (
          <p className="card-text">
            Assigned to: {quad.assigned_to.join(", ")}
          </p>
        )}
        {editable && (
          <h3>{formatter.format(money)}</h3>
        )}
        <p>Last year: {formatter.format(moneyLastYear)}</p>
        <h3>
          {numVisitedHouses} /{" "}
          {numHouses} visited (
          {(
            numVisitedHouses /
            numHouses *
            100
          ).toFixed(1)}
          %)
        </h3>
        {Object.keys(moneyByScout).map((user_id: any) => (
          <p className="text-muted" key={user_id}>
            {users.find((u: User) => u.id === user_id)?.name || "Unknown"}: {formatter.format(moneyByScout[user_id])}
          </p>
        ))}
        <Link
          to={"/map/sales/" + quad.id + "/"}
          className="btn btn-primary"
        >
          View Map
        </Link>
        {editable && (
          <Link
            to={"/quads/" + quad.id + "/edit"}
            className="btn btn-link ms-2"
          >
            Edit
          </Link>
        )}
      </Card>
    </div>
  );
};

export default QuadCard;
