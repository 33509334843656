import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import React, { useEffect, useRef, useState } from "react";
import * as turf from "@turf/turf";
import toast from "react-hot-toast";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import { TreeTagHouse, TreeTagQuad } from "../../store/types";
import { useSelector } from "react-redux";
import { features } from "process";

interface DrawPolygonProps {
  theme: "light" | "dark";
  polygon: number[][];
  quad_id: number;
  onChange: (polygon: any) => void;
}

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  defaultMode: "draw_polygon",
});

function isValidPolygon(polygon: any): boolean {
  if (!polygon || polygon.length < 4) {
    return false;
  }
  for (let i = 0; i < polygon.length; i++) {
    if (polygon[i] && polygon[i].length !== 2) {
      return false;
    }
  }
  return true;
}

const DrawPolygon: React.FC<DrawPolygonProps> = ({ theme, polygon, quad_id, onChange }) => {
  const [editing, setEditing] = useState(false);
  const mapRef = useRef<any>(null);

  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);
  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);

  const otherQuadsData: GeoJSON.Feature<GeoJSON.Geometry>[] = quads.filter((q) => q.id !== quad_id)
    .map((q) => {
      return {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [q.polygon],
        },
        properties: {
          quad_id: q.id,
        },
      };
    });

  const housesData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: houses.filter((house) => house.quad_id === quad_id)
      .map((house) => {
        return {
          type: "Feature",
          properties: {
            icon: "house",
            "icon-image": "house",
          },
          geometry: {
            type: "Point",
            coordinates: [house.lng, house.lat],
          },
        };
      })
  };

  const updateArea = (e: any) => {
    const data = draw.getAll();
    if (data.features.length > 0) {
      onChange((data.features[0].geometry as any).coordinates[0]);
    } else {
      onChange([]);
      if (e.type !== "draw.delete") toast("Click the map to draw a polygon.");
    }
  };

  useEffect(() => {
    if (isValidPolygon(polygon)) {
      draw.deleteAll();
      let polygonFeature = turf.polygon([polygon]);
      draw.add(polygonFeature);
      let bbox = turf.bbox(polygonFeature);
      mapRef.current.fitBounds(bbox, {
        padding: 50,
      });
    }
  }, [polygon]);

  return (
    <div className="my-2">
      {!editing && (
        <button onClick={() => setEditing(true)} className="btn btn-outline-secondary">
          Edit Polygon
        </button>
      )}
      <div className={editing ? "polygon-map" : "d-none"}>
        <MapProvider>
          <Map
            id="polygon-map"
            ref={(ref) => {
              if (ref && !mapRef.current) {
                mapRef.current = ref.getMap();
                if (!mapRef.current._controls.some((control: any) => control.modes)) {
                  mapRef.current.addControl(draw);
                  mapRef.current.on("draw.create", updateArea);
                  mapRef.current.on("draw.delete", updateArea);
                  mapRef.current.on("draw.update", updateArea);
                  mapRef.current.on("idle", () => {
                    mapRef.current.resize();
                  });
                  if (isValidPolygon(polygon)) {
                    draw.deleteAll();
                    let polygonFeature = turf.polygon([polygon]);
                    draw.add(polygonFeature);
                    let bbox = turf.bbox(polygonFeature);
                    mapRef.current.fitBounds(bbox, {
                      padding: 50,
                    });
                  }
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: -122.21,
              latitude: 47.63,
              zoom: 13,
            }}
            mapStyle={theme === "light" ?
              "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
              "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
            maxBounds={MAP_BOUNDS}
          >
            {otherQuadsData.map((quad, i) => (
              <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quad}>
                <Layer
                  id={"quad" + i}
                  type="fill"
                  paint={{
                    "fill-color": "#ff0000",
                    "fill-opacity": 0.2,
                  }}
                />
                <Layer
                  id={"quad-label-" + i}
                  type="symbol"
                  layout={{
                    "text-field": ["get", "quad_id"],
                    "text-size": 12,
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  }}
                  paint={{
                    "text-color": "#000000",
                  }}
                />
              </Source>
            ))}
            {housesData && (
              <Source
                id={"houses"}
                type="geojson"
                data={housesData}
                key={"houses"}
              >
                <Layer
                  id={"houses"}
                  type="circle"
                  paint={{
                    "circle-color": "#000000",
                    "circle-radius": {
                      'base': 1.75,
                      'stops': [
                        [12, 2],
                        [22, 180]
                      ]
                    },
                    "circle-stroke-width": 2,
                    "circle-stroke-color": "#ffffff",
                  }}
                />
              </Source>
            )}
          </Map>
        </MapProvider>
      </div>
    </div>
  );
};

export default DrawPolygon;
