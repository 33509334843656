import React, { useState, useEffect } from "react";
import { formatter } from "../../utils/Formatter";
import { useSelector } from "react-redux";
import { ScoutStatistic, User } from "../../store/types";
import { getSeason } from "../../utils/API";

interface LeaderboardProps {
  theme: "light" | "dark";
}

const Leaderboard: React.FC<LeaderboardProps> = ({ theme }) => {
  const [selectedYearOffset, setSelectedYearOffset] = useState(0);
  const [leaderboard, setLeaderboard] = useState<ScoutStatistic[]>([]);

  const scoutStats = useSelector((state: any) => state.scoutStats.data as ScoutStatistic[]);
  const users = useSelector((state: any) => state.users.data as User[]);

  useEffect(() => {
    const scoutStatsInYear = scoutStats.filter((stats) => stats.year === getSeason(new Date()) - selectedYearOffset);
    const leaderboard = scoutStatsInYear
      .sort((a, b) => {
        const aSales = a.money_tags + a.money_donations;
        const bSales = b.money_tags + b.money_donations;
        return bSales - aSales;
      })
      .filter((stats) => {
        return stats.money_tags + stats.money_donations > 100;
      });
    setLeaderboard(leaderboard);
  }, [scoutStats, users, selectedYearOffset]);

  if (leaderboard.length === 0) {
    return (
      <div className="container mt-2">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-5 fw-bold">No sales yet.</h1>
            <p className="lead mb-2">
              The leaderboard will update when the first scout sells more than
              $100.
            </p>
            <div className="btn-group" role="group" aria-label="Select options and refresh">
              <select value={selectedYearOffset} onChange={(e) => {
                setSelectedYearOffset(Number(e.target.value));
              }} className="form-select">
                <option value={0}>This Year</option>
                <option value={1}>Last Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="display-5 fw-bold">Sales Leaderboard</h1>
          <div className="btn-group" role="group" aria-label="Select options and refresh">
            <select value={selectedYearOffset} onChange={(e) => {
              setSelectedYearOffset(Number(e.target.value));
            }} className="form-select">
              <option value={0}>This Year</option>
              <option value={1}>Last Year</option>
            </select>
          </div>
        </div>
        <div className="col-12">
          <table className={"table " + (theme === "dark" && "text-white")}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Sales</th>
                <th scope="col"># Door Knocks</th>
                <th scope="col"># Trees</th>
                <th scope="col">Avg. Donation</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map((item, index) => {
                const user = users.find((user) => user.id === item.user_id);
                if (!user) return null;
                const totalSales = item.money_tags + item.money_donations;
                const avgDonation = totalSales / (item.num_tags + item.num_donations);
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{user.name}</td>
                    <td>{formatter.format(totalSales)}</td>
                    <td>{item.knocks}</td>
                    <td>{item.num_tags}</td>
                    <td>{formatter.format(isNaN(avgDonation) ? 0 : avgDonation)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
