import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatter, TREE_TAG_PRICE } from "../../utils/Formatter";
import { formatEmail, formatPhone } from "../pickup/TagInfo";
import { shortenAddress } from "../quads/HouseInfo";
import UserTagify from "../quads/UserTagify";
import PickupEditorModal from "./PickupEditorModal";
import { TreeTagHouse, TreeTagResponse, User } from "../../store/types";
import { useSelector } from "react-redux";

interface AllTagsProps {
  theme: "light" | "dark";
}

function matchesSearch(house: TreeTagHouse | undefined, tag: TreeTagResponse, scoutName: string, creatorName: string, searchTerm: string) {
  const fields: string[] = [
    house?.address || "",
    tag.name,
    tag.email,
    tag.phone,
    scoutName,
    creatorName,
  ];
  return fields.some((field) => field && field.toLowerCase().includes(searchTerm.toLowerCase()));
}

function getUserName(users: User[], id: number) {
  const user = users.find((user) => user.id === id);
  return user ? user.name : "Unknown";
}

const AllTags: React.FC<AllTagsProps> = ({ theme }) => {
  const [duplicateTagAddresses, setDuplicateTagAddresses] = useState<string[]>([]);
  const [editingTreeID, setEditingTreeID] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [allTypes, setAllTypes] = useState(false);
  const [scoutFilter, setScoutFilter] = useState<number[]>([]);

  const responses = useSelector((state: any) => state.responses.data as TreeTagResponse[]);
  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const users = useSelector((state: any) => state.users.data as User[]);

  const responseObj = responses.map((response) => ({
    house: houses.find((house) => house.id === response.house_id),
    scoutName: getUserName(users, response.scout),
    creatorName: getUserName(users, response.created_by),
    response,
  }));

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12 no-print">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Search
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search by address, name, email, or phone"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12 no-print">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text h-100" id="basic-addon1">
                Scout
              </span>
            </div>
            <div className="col-8">
              <UserTagify
                defaultValue={scoutFilter}
                onChange={setScoutFilter}
                placeholder="Filter by scout"
                maxTags={1}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <Link className="btn btn-outline-primary me-2 mb-1 w-xs-100" to="/manage">
            View Email Addresses
          </Link>
          <button
            className="btn btn-outline-primary me-2 mb-1 w-xs-100"
            onClick={() => {
              setAllTypes(!allTypes);
            }}
          >
            {allTypes ? "Show Only Tags" : "Show All Types"}
          </button>
          <hr />
          {duplicateTagAddresses.length > 0 && (
            <div>
              <div className="alert alert-warning">
                <h4 className="alert-heading">Duplicate Entries</h4>
                <p>
                  The following addresses have been entered more than once, and will
                  overlap on the pickup map, causing confusion.
                  Please review the list below and resolve any duplicates.
                </p>
                <ul>
                  {duplicateTagAddresses.map((address) => (
                    <li key={address}>{shortenAddress(address.split(",")[0])}</li>
                  ))}
                </ul>
              </div>
              <hr />
            </div>
          )}
          {responses.length > 0 ? (
            <table className={"table " + (theme === "dark" && "text-white")}>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th># Tags</th>
                  <th>$ Collected</th>
                  <th>$ Owed</th>
                  <th>Scout</th>
                  <th>Entered by</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {responseObj.filter(({ response, house, scoutName, creatorName }) => {
                  return !searchTerm || matchesSearch(house, response, scoutName, creatorName, searchTerm);
                }).map(({ response, house, scoutName, creatorName }) => {
                  let owed = TREE_TAG_PRICE * response.num_tags - response.money;
                  if (owed < 0) {
                    owed = 0;
                  }
                  return (
                    <tr key={response.id} data-id={response.id}>
                      <td>{house?.address}</td>
                      <td>{response.name}</td>
                      <td>{formatEmail(response.email)}</td>
                      <td>{formatPhone(response.phone)}</td>
                      <td>{response.num_tags}</td>
                      <td>{formatter.format(response.money)}</td>
                      <td>{formatter.format(owed)}</td>
                      <td>{scoutName + (response.stripe_session_id ? " (sold online)" : "")}</td>
                      <td>{creatorName}</td>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={() => setEditingTreeID(response.id)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="text-center">
              {allTypes ? (
                <p className="text-muted">No responses found.</p>
              ) : (
                <p className="text-muted">No tags found.</p>
              )}
            </div>
          )}
        </div>
      </div>
      <PickupEditorModal
        open={editingTreeID !== 0}
        treeTagID={editingTreeID}
        onClose={() => setEditingTreeID(0)}
      />
    </div>
  );
};

export default AllTags;
