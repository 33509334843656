import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface Product {
  description: string;
  stripeUrl: string;
  stripeUrlMultiple?: string;
  suggestedDonation: number;
}

interface EditProductModalProps {
  show: boolean;
  handleClose: () => void;
  productName: string;
  expectQty?: boolean;
  productData: Product;
  saveProduct: (product: Product) => void;
}

const EditProductModal: React.FC<EditProductModalProps> = ({ show, expectQty, handleClose, productName, productData, saveProduct }) => {
  const [product, setProduct] = useState<Product>(productData);

  const handleInputChange = (field: string, value: string | number) => {
    setProduct({ ...product, [field]: value });
  };

  const handleSave = () => {
    saveProduct(product);
    handleClose();
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={{ maxWidth: 600, bgcolor: 'background.paper', p: 4, mx: 'auto', mt: '10%' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Edit Product</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Product Name: {productName}</Typography>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            value={product.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Stripe URL"
            variant="outlined"
            fullWidth
            value={product.stripeUrl}
            onChange={(e) => handleInputChange('stripeUrl', e.target.value)}
            sx={{ mt: 2 }}
          />
          {expectQty && (
            <TextField
              label="Stripe URL (QTY > 1)"
              variant="outlined"
              fullWidth
              value={product.stripeUrlMultiple}
              onChange={(e) => handleInputChange('stripeUrlMultiple', e.target.value)}
              sx={{ mt: 2 }}
            />
          )}
          <TextField
            label="Suggested Donation"
            variant="outlined"
            fullWidth
            type="number"
            value={product.suggestedDonation}
            onChange={(e) => handleInputChange('suggestedDonation', parseFloat(e.target.value))}
            sx={{ mt: 2 }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mr: 2 }}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditProductModal;