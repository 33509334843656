import { Component } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";

interface EfficiencyModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface EfficiencyModalState {
  date: string;
  hours: number;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

class EfficiencyModal extends Component<
  EfficiencyModalProps,
  EfficiencyModalState
> {
  constructor(props: EfficiencyModalProps) {
    super(props);
    this.state = {
      hours: 0,
      date: new Date().toISOString().split("T")[0],
    };
  }

  save() {
  //  TODO: Save hours worked
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="addEfficiencyModalLabel">
                Log Hours
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="money">Date: </label>
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.date}
                    onChange={(e) => this.setState({ date: e.target.value })}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="money">Hours: </label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.hours}
                    onChange={(e) =>
                      this.setState({ hours: parseFloat(e.target.value) })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer mt-5">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  this.save();
                }}
              >
                Log Hours
              </button>
              <button
                type="button"
                className="btn btn-default ms-2"
                onClick={this.props.onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default EfficiencyModal;
