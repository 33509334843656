import React, { useState } from "react";
import EditDriverModal from "./EditDriverModal";
import { User } from "../../store/types";
import { useSelector } from "react-redux";

interface ManageDriversProps {
  theme: "light" | "dark";
}

const ManageDrivers: React.FC<ManageDriversProps> = ({ theme }) => {
  const [driverEditing, setDriverEditing] = useState(0);

  const users = useSelector((state: any) => state.users.data as User[]);
  const drivers = users.filter((user: User) => user.roles.includes("TreeTagsDriver"));

  const loadDrivers = () => {
    // TODO: Load drivers
  };

  return (
    <div>
      <h3>Drivers</h3>
      <hr />
      <table className={"table " + (theme === "dark" && "text-white")}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone</th>
            <th># Trees Assigned</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {drivers.map((driver) => {
            const numTrees = 0;
            return (
              <tr key={driver.id}>
                <td>{driver.id}</td>
                <td>{driver.name}</td>
                <td>{driver.phone}</td>
                <td>{numTrees}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setDriverEditing(driver.id)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {drivers.length === 0 && <p className="text-muted">No drivers found.</p>}
      <EditDriverModal
        driverId={driverEditing}
        onClose={() => setDriverEditing(0)}
        onSave={() => {
          loadDrivers();
          setDriverEditing(0);
        }}
      />
    </div>
  );
};

export default ManageDrivers;
