import { Component } from "react";

interface HouseSearchProps { }

interface HouseSearchState {
  searchTerm: string;
}

class HouseSearch extends Component<HouseSearchProps, HouseSearchState> {
  constructor(props: HouseSearchProps) {
    super(props);
    this.state = {
      searchTerm: "",
    };
  }

  doSearch() {
    // TODO: Search for houses???
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-5 fw-bold">House Search</h1>
          </div>
          <div className="col-12 text-center">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search for an address"
                aria-label="Search for an address"
                aria-describedby="search-button"
                value={this.state.searchTerm}
                onChange={(e) => this.setState({ searchTerm: e.target.value })}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="search-button"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HouseSearch;
