import { useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import UserTagify from "../quads/UserTagify";

interface EditDriverModalProps {
  driverId: number;
  onClose: () => void;
  onSave: () => void;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const EditDriverModal: React.FC<EditDriverModalProps> = ({ driverId, onClose, onSave }) => {
  const [drivers, setDrivers] = useState<number[]>([]);

  const save = () => {
    let new_user_id = drivers[0];
    if (driverId === 0) return;
    if (!new_user_id) new_user_id = 0;

    // TODO: Assign driver
  };

  return (
    <Modal
      open={driverId !== 0}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addEditDriverModalLabel">
              Change Driver
            </h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="driver">Driver</label>
                <UserTagify
                  defaultValue={[driverId]}
                  onChange={(ids) => {
                    setDrivers(ids.length > 0 ? [ids[0]] : []);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
              disabled={drivers.length > 1}
            >
              Assign
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditDriverModal;
