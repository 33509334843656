import React, { useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { TreeTagPickupGroup } from "../../store/types";

interface RenameGroupModalProps {
  open: boolean;
  onClose: () => void;
  group: TreeTagPickupGroup;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const RenameGroupModal: React.FC<RenameGroupModalProps> = ({
  open,
  onClose,
  group,
}) => {
  const [name, setName] = useState(group.name);

  const save = () => {
    // TODO: Rename pickup group
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addRenameGroupModalLabel">
              Rename Group
            </h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="driver">Name</label>
                <input
                  className="form-control"
                  id="driver"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
            >
              Rename
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RenameGroupModal;
