import React, { useState } from "react";
import Card from "@mui/material/Card";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { formatter } from "../../utils/Formatter";
import QuadCard from "../quads/QuadCard";
import ResponseChart from "./ResponseChart";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/API";
import RewardLevelsModal, { getRewardLevelForTotalSales } from "./RewardLevelsModal";
import { TreeTagGoal, TreeTagManualEntry, TreeTagQuad, ScoutStatistic, TreeTagRewardLevel } from "../../store/types";

const Dashboard: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const userId = id ? parseInt(id) : getUserInfo().id;

  const [levelsModalOpen, setLevelsModalOpen] = useState(false);

  const scoutStats = useSelector((state: any) => state.scoutStats.data as ScoutStatistic[]);
  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);
  const rewardLevels = useSelector((state: any) => state.rewardLevels.data as TreeTagRewardLevel[]);
  const goals = useSelector((state: any) => state.goals.data as TreeTagGoal[]);
  const manualEntries = useSelector((state: any) => state.manualEntries.data as TreeTagManualEntry[]);

  const assignedQuads = quads.filter((q) => q.assigned_to.includes(userId));
  const myManualEntries = manualEntries.filter((m) => m.user_id === userId);
  const goal = goals.find((g) => g.user_id === userId);
  const myStats = scoutStats.find((s) => s.user_id === userId);
  const totalSales = myStats?.money_tags || 0;
  const totalTags = myStats?.num_tags || 0;
  const avgDonation = totalTags ? (totalSales / totalTags) : 0;
  const manualTotal = myManualEntries.reduce((acc, m) => acc + m.amount, 0);

  const setGoal = () => {
    let amount = prompt("Enter your goal in dollars");
    if (amount && parseFloat(amount)) {
      // TODO: Update goal
    } else {
      toast.error("Please enter a valid number", { id: "error" });
    }
  };

  const leftToGoal = (goal?.goal || 0) - totalSales;
  const { level, rewardMessage } = getRewardLevelForTotalSales(totalSales, rewardLevels);

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h2>Your Goal</h2>
              {goal ? (
                <div>
                  <p className="lead mb-0">
                    {formatter.format(totalSales)} /{" "}
                    {formatter.format(goal.goal)}
                    <span className="small text-muted">
                      <button
                        className="btn btn-link p-0 ms-2 mb-1"
                        onClick={setGoal}
                      >
                        Edit
                      </button>
                    </span>
                  </p>
                  <p>
                    {leftToGoal <= 0
                      ? "Congrats! You've reached your goal."
                      : formatter.format(leftToGoal) + " left to reach your goal."}
                  </p>
                </div>
              ) : (
                <p>
                  Ready to set a goal? Click{" "}
                  <button className="btn btn-link p-0 mb-1" onClick={setGoal}>
                    here
                  </button>{" "}
                  to set one.
                </p>
              )}
              <hr />
              {level !== null && (
                <>
                  <h2>
                    Estimated Earnings{" "}
                    <span
                      className="badge"
                      style={{ backgroundColor: level.color }}
                      title={level.name}
                    >
                      {level.name}
                    </span>
                  </h2>
                  <h1 className="display-4">
                    {formatter.format(totalSales * level.rate)}
                  </h1>
                  <p className="text-muted">🎁 {level.draws} Gift Card Drawings</p>
                  <div className="alert alert-info">
                    <p>
                      {rewardMessage}{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => setLevelsModalOpen(true)}
                      >
                        Learn more
                      </button>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 col-sm-12 row">
              <div className="col-lg-6 col-sm-12">
                <h2>
                  Your Stats
                </h2>
                <h4>Total Sales: {formatter.format(totalSales)}</h4>
                <h4>Total Tree Tags Sold: {totalTags}</h4>
                <h4>Avg Donation: {formatter.format(avgDonation)}</h4>
              </div>
              <div className="col-lg-6 col-sm-12">
                <ResponseChart />
              </div>
              <div className="col-12">
                <hr />
                <h2>Efficiency</h2>
                <p className="small text-muted">
                  Track efficiency by recording hours worked
                </p>
                <Link to="/efficiency" className="btn btn-outline-primary">
                  Track Efficiency
                </Link>
              </div>
            </div>
          </div>
          <Card variant="outlined" className="mt-2 p-3">
            <Link to="/list" className="btn btn-outline-primary me-2 mt-2">
              Enter Tags
            </Link>
            <hr />
            <h3>Your Quads</h3>
            <p>
              Your Scout ID is{" "}
              <pre className="d-inline">{localStorage.getItem("scout_id")}</pre>
            </p>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {assignedQuads?.map((quad) => (
                <QuadCard key={quad.id} quad={quad} />
              ))}
              <div className="col">
                <Card variant="outlined" className="m-2 p-3">
                  <h2>Manual Entries</h2>
                  <h3>{formatter.format(manualTotal)}</h3>
                  <Link className="btn btn-outline-primary m-2" to="/manual">
                    View Entries
                  </Link>
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <RewardLevelsModal
        open={levelsModalOpen}
        onClose={() => setLevelsModalOpen(false)}
      />
    </div>
  );
};

export default Dashboard;
