import React, { useState } from 'react';
import GroupInfo from './GroupInfo';
import TagInfo from './TagInfo';
import PickupMap from './PickupMap';
import { TreeTagPickupGroup, TreeTagResponse } from '../../store/types';
import { useSelector } from 'react-redux';

interface PickupPageProps {
  theme: "light" | "dark";
}

const PickupPage: React.FC<PickupPageProps> = (props) => {
  const trees = useSelector((state: any) => state.trees.data as TreeTagResponse[]);
  const pickupGroups = useSelector((state: any) => state.pickupGroups.data as TreeTagPickupGroup[]);

  const [treeSelected, setTreeSelected] = useState<TreeTagResponse | null>(null);
  const [groupSelected, setGroupSelected] = useState<TreeTagPickupGroup | null>(null);

  const updateTag = (tag: any) => { };

  let overlay = null;
  if (treeSelected) {
    overlay = (
      <TagInfo
        tree={treeSelected}
        onClose={() => {
          setTreeSelected(null);
        }}
        onUpdate={() => {
          if (treeSelected) {
            updateTag(treeSelected);
          }
        }}
        theme={props.theme}
      />
    );
  } else if (groupSelected) {
    overlay = (
      <GroupInfo
        group={groupSelected}
        onClose={() => {
          setGroupSelected(null);
        }}
        theme={props.theme}
      />
    );
  }
  return (
    <div className="h-100">
      {overlay}
      <div className="h-100" style={{ display: overlay !== null ? "none" : "block" }}>
        <PickupMap
          theme={props.theme}
          trees={trees}
          groups={pickupGroups}
          onTreeClick={setTreeSelected}
          onGroupClick={setGroupSelected}
          treeSelected={treeSelected}
          groupSelected={groupSelected}
        />
      </div>
    </div>
  );
};

export default PickupPage;
