import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface Pickup {
  date: string;
  dropoffAllowed: boolean;
  dropoffLocation?: string;
  dropoffHours?: string;
  zipCodes: number[];
}

interface EditPickupModalProps {
  show: boolean;
  handleClose: () => void;
  pickupData: Pickup;
  savePickup: (pickup: Pickup) => void;
}

const EditPickupModal: React.FC<EditPickupModalProps> = ({ show, handleClose, pickupData, savePickup }) => {
  const [pickup, setPickup] = useState<Pickup>(pickupData);
  const [zipCodes, setZipCodes] = useState<string>(pickupData.zipCodes.join(', '));

  const handleInputChange = (field: string, value: any) => {
    setPickup({ ...pickup, [field]: value });
  };

  const handleSave = () => {
    savePickup(pickup);
    handleClose();
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={{ maxWidth: 600, bgcolor: 'background.paper', p: 4, mx: 'auto', mt: '10%' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Edit Pickup</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Pickup Date"
            variant="outlined"
            fullWidth
            type="date"
            value={pickup.date}
            onChange={(e) => handleInputChange('date', e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Pickup Zip Codes (Comma Separated)"
            variant="outlined"
            fullWidth
            value={zipCodes}
            onChange={(e) => setZipCodes(e.target.value)}
            onBlur={() => {
              const zips = zipCodes.split(',').map((zip) => parseInt(zip.trim()));
              setZipCodes(zips.join(', '));
              handleInputChange('zipCodes', zips);
            }}
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pickup.dropoffAllowed}
                onChange={(e) => handleInputChange('dropoffAllowed', e.target.checked)}
              />
            }
            label="Dropoff Allowed"
            sx={{ mt: 2 }}
          />
          {pickup.dropoffAllowed && (
            <>
              <TextField
                label="Dropoff Location"
                variant="outlined"
                fullWidth
                value={pickup.dropoffLocation || ''}
                onChange={(e) => handleInputChange('dropoffLocation', e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Dropoff Hours (Between)"
                variant="outlined"
                fullWidth
                value={pickup.dropoffHours || ''}
                onChange={(e) => handleInputChange('dropoffHours', e.target.value)}
                sx={{ mt: 2 }}
              />
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mr: 2 }}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPickupModal;