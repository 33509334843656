import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/Params";
import { getSeason, getUserInfo } from "../utils/API";

// Generic function to create async thunk
const createFetchThunk = (name: string) => {
  return createAsyncThunk(`${name}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_URL}/private/${name}`, {
        headers: {
          Authorization: jwt,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  });
};

// Generic function to create slice
const createGenericSlice = (name: string, fetchThunk: any) => {
  return createSlice({
    name,
    initialState: {
      data: [],
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.errorMessage = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchThunk.pending, (state) => {
          state.isFetching = true;
          state.isError = false;
          state.isSuccess = false;
          state.errorMessage = "";
        })
        .addCase(fetchThunk.fulfilled, (state, action) => {
          state.isFetching = false;
          state.isSuccess = true;
          state.data = action.payload;
        })
        .addCase(fetchThunk.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = action.payload as string;
        });
    },
  });
};

const season = getSeason(new Date());
const userInfo = getUserInfo();

// Create thunks for responses and houses
export const fetchUsers = createFetchThunk("users");
export const fetchResponses = createFetchThunk(`tables/tree_tags_responses?scout=${userInfo?.sub||-1}&season=${season}`);
export const fetchHouses = createFetchThunk("tables/tree_tags_houses");
export const fetchQuads = createFetchThunk("tables/tree_tags_quads");
export const fetchHours = createFetchThunk("tables/tree_tags_hours");
export const fetchRewardLevels = createFetchThunk("tables/tree_tags_reward_levels");
export const fetchGoals = createFetchThunk("tables/tree_tags_goals");
export const fetchManualEntries = createFetchThunk("tables/tree_tags_manual_entries");
export const fetchPickupGroups = createFetchThunk("tables/tree_tags_pickup_groups");
export const fetchScoutStats = createFetchThunk("tables/tree_tags_scout_stats");
export const fetchYearStats = createFetchThunk("tables/tree_tags_year_stats");
export const fetchTrees = createFetchThunk(`tables/tree_tags_responses?response=${0}&season=${season}`);
export const fetchSiteConfig = createFetchThunk("custom/get-public-site-params");

// Create slices for responses and houses
export const usersSlice = createGenericSlice("users", fetchUsers);
export const responsesSlice = createGenericSlice("responses", fetchResponses);
export const housesSlice = createGenericSlice("houses", fetchHouses);
export const quadsSlice = createGenericSlice("quads", fetchQuads);
export const hoursSlice = createGenericSlice("hours", fetchHours);
export const rewardLevelsSlice = createGenericSlice("reward_levels", fetchRewardLevels);
export const goalsSlice = createGenericSlice("goals", fetchGoals);
export const manualEntriesSlice = createGenericSlice("manual_entries", fetchManualEntries);
export const pickupGroupsSlice = createGenericSlice("pickup_groups", fetchPickupGroups);
export const scoutStatsSlice = createGenericSlice("scout_stats", fetchScoutStats);
export const yearStatsSlice = createGenericSlice("year_stats", fetchYearStats);
export const treesSlice = createGenericSlice("trees_to_pickup", fetchTrees);
export const siteConfigSlice = createGenericSlice("site_config", fetchSiteConfig);

export const usersReducer = usersSlice.reducer;
export const responsesReducer = responsesSlice.reducer;
export const housesReducer = housesSlice.reducer;
export const quadsReducer = quadsSlice.reducer;
export const hoursReducer = hoursSlice.reducer;
export const rewardLevelsReducer = rewardLevelsSlice.reducer;
export const goalsReducer = goalsSlice.reducer;
export const manualEntriesReducer = manualEntriesSlice.reducer;
export const pickupGroupsReducer = pickupGroupsSlice.reducer;
export const scoutStatsReducer = scoutStatsSlice.reducer;
export const yearStatsReducer = yearStatsSlice.reducer;
export const treesReducer = treesSlice.reducer;
export const siteConfigReducer = siteConfigSlice.reducer;
