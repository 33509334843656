import React, { useEffect, useState } from "react";
import AssignGroupModal from "./AssignGroupModal";
import { TreeTagPickupGroup, TreeTagResponse, User } from "../../store/types";
import { useSelector } from "react-redux";

interface ManagePickupGroupsProps {
  theme: string;
}

interface PickupGroupStats {
  group: TreeTagPickupGroup;
  collected: number;
  skipped: number;
  left: number;
  assignedTo: string;
}

const ManagePickupGroups: React.FC<ManagePickupGroupsProps> = ({ theme }) => {
  const [groupSelectedForAssignment, setGroupSelectedForAssignment] = useState<TreeTagPickupGroup | null>(null);
  const [pickupGroupStats, setPickupGroupStats] = useState<PickupGroupStats[]>([]);

  const pickupGroups = useSelector((state: any) => state.pickupGroups.data as TreeTagPickupGroup[]);
  const trees = useSelector((state: any) => state.trees.data as TreeTagResponse[]);
  const users = useSelector((state: any) => state.users as User[]);


  useEffect(() => {
    const treesByPickupGroup: { [key: number]: TreeTagResponse[] } = {};
    trees.forEach((response) => {
      if (!treesByPickupGroup[response.pickup_group_id]) {
        treesByPickupGroup[response.pickup_group_id] = [];
      }
      treesByPickupGroup[response.pickup_group_id].push(response);
    });

    const groupStats: PickupGroupStats[] = pickupGroups.map((group) => {
      const groupResponses = treesByPickupGroup[group.id] || [];
      const collected = groupResponses.filter((response) => response.picked_up_status === 0).length;
      const skipped = groupResponses.filter((response) => response.picked_up_status === 1).length;
      const left = groupResponses.filter((response) => response.picked_up_status === 2).length;
      const assignedTo = users.find((user) => user.id === group.assigned_to)?.name || "N/A";
      return { group, collected, skipped, left, assignedTo };
    });
    setPickupGroupStats(groupStats);
  }, [pickupGroups, trees, users]);

  const generatePickupGroups = () => {
    if (window.confirm(
      "Are you sure you would like to automatically re-generate pickup groups? This will remove all old assignments."
    )) {
      // TODO: Generate pickup groups
    }
  };

  const printPickupGroups = () => {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php",
        "_blank"
      );
    }
  };

  const printPickupGroup = (id: number) => {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php?id=" + id,
        "_blank"
      );
    }
  };

  return (
    <div>
      <h3>Pickup Groups</h3>
      <button
        className="btn btn-outline-primary me-2"
        onClick={generatePickupGroups}
      >Generate</button>
      <button
        className="btn btn-outline-primary me-2"
        onClick={printPickupGroups}
      >Print</button>
      <table className={"table " + (theme === "dark" && "text-white")}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Driver</th>
            <th># Collected</th>
            <th># Skipped</th>
            <th># Left</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pickupGroupStats.map((stats) => (
            <tr key={stats.group.id}>
              <td>{stats.group.name}</td>
              <td>{stats.assignedTo}</td>
              <td>{stats.collected}</td>
              <td>{stats.skipped}</td>
              <td>{stats.left}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary m-1"
                  onClick={() => setGroupSelectedForAssignment(stats.group)}
                >Assign</button>
                <button
                  className="btn btn-sm btn-primary m-1"
                  onClick={() => printPickupGroup(stats.group.id)}
                >Print</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pickupGroups.length === 0 && (
        <p className="text-muted">
          No pickup groups found.
        </p>
      )}
      {groupSelectedForAssignment && (
        <AssignGroupModal
          open={groupSelectedForAssignment !== null}
          group={groupSelectedForAssignment}
          onClose={() => setGroupSelectedForAssignment(null)}
        />
      )}
    </div>
  );
};

export default ManagePickupGroups;
