import React from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/Formatter";
import { ScoutStatistic, User } from "../../store/types";
import { useSelector } from "react-redux";

const ScoutStats: React.FC = () => {
  const users = useSelector((state: any) => state.users.data as User[]);
  const scoutStats = useSelector((state: any) => state.scoutStats.data as ScoutStatistic[]);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <h2>
            Scouts Stats
          </h2>
        </div>
      </div>
      <div className="row">
        {scoutStats.map((statistic) => {
          const user = users.find((user) => user.id === statistic.user_id);
          if (!user) return null;
          const totalSales = statistic.money_tags + statistic.money_donations;
          return (
            <div className="col-md-4 col-sm-12 p-2" key={user.id}>
              <Card variant="outlined" className="p-3">
                <h2>{user.name}</h2>
                <p>
                  {formatter.format(totalSales)}{" "}
                  <span className="small text-muted">total sales</span>
                </p>
                <Link
                  className="btn btn-outline-primary m-2"
                  to={"/scouts/" + user.id + "/"}
                >
                  View Dashboard
                </Link>
              </Card>
            </div>
          );
        })}
        {users.length === 0 && (
          <div className="col-md-4 col-sm-12 p-2">
            <Card variant="outlined" className="p-3">
              <h2>No Scouts</h2>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoutStats;
