import React, { useEffect, useState } from "react";
import "./App.css";
import MainPage from "./components/ui/MainPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Toaster } from "react-hot-toast";
import * as serviceWorker from "./serviceWorkerRegistration";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from "react-redux";
import store from "./store/store";
import { LOGIN_URL } from "./utils/Params";

const App: React.FC = () => {
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const preferredTheme = prefersDarkMode ? "dark" : "light";
  let savedTheme = localStorage.getItem("theme");
  if (savedTheme !== "light" && savedTheme !== "dark") {
    savedTheme = null;
  }

  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<any>({});
  const [theme, setTheme] = useState<"light" | "dark">(savedTheme || preferredTheme);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
    updateTheme(theme);
  }, [theme]);

  const onServiceWorkerUpdate = (registration: any) => {
    setWaitingWorker(registration && registration.waiting);
    setNewVersionAvailable(true);
  };

  const updateServiceWorker = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setNewVersionAvailable(false);
      window.location.reload();
    }
  };

  const updateTheme = (newTheme: "light" | "dark") => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    const app = document.querySelector("body");
    if (app) {
      if (newTheme === "dark") {
        app.classList.add("App-dark");
      } else {
        app.classList.remove("App-dark");
      }
    }
  };

  const themeConfig = createTheme({
    palette: {
      mode: theme,
    },
  });

  // check if "token" in url query
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  if (token) {
    localStorage.setItem("jwt", token);
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  if (!localStorage.getItem("jwt")) {
    window.location.href = LOGIN_URL;
    return null;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        <div className={"App" + (theme === "dark" ? " App-dark" : "")}>
          <Toaster
            toastOptions={{
              style: {
                background: theme === "dark" ? "#f0f0f0" : '#363636',
                color: theme === "dark" ? '#000' : '#fff',
              },
              duration: 6000,
              success: {
                duration: 3000,
              },
            }}
          />
          <MainPage
            newVersionAvailable={newVersionAvailable}
            onSWUpdateRequest={updateServiceWorker}
            theme={theme}
            onThemeUpdate={(newTheme: "light" | "dark") => {
              updateTheme(newTheme);
            }}
          />
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;