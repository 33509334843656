import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { CheckmarkIcon } from "react-hot-toast";
import { shortenAddress } from "../quads/HouseInfo";
import AssignGroupModal from "./AssignGroupModal";
import CombineGroupModal from "./CombinePickupGroupModal";
import RenameGroupModal from "./RenameGroupModal";
import StaticMap from "./StaticMap";
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from "@mui/material/IconButton";
import { MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import { TreeTagHouse, TreeTagPickupGroup, TreeTagResponse } from "../../store/types";
import { getUserInfo, isAdmin } from "../../utils/API";
import { TREE_TAG_PRICE } from "../../utils/Formatter";
import { useSelector } from "react-redux";

interface GroupInfoProps {
  group: TreeTagPickupGroup;
  onClose: () => void;
  theme: "light" | "dark";
}

const userInfo = getUserInfo();

const GroupInfo: React.FC<GroupInfoProps> = ({ group, onClose, theme }) => {
  const [assignDriverModalOpen, setAssignDriverModalOpen] = useState(false);
  const [renameGroupModalOpen, setRenameGroupModalOpen] = useState(false);
  const [combineGroupModalOpen, setCombineGroupModalOpen] = useState(false);

  const trees = useSelector((state: any) => state.trees.data as TreeTagResponse[]);
  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);

  const responsesInPickupGroup = trees.filter((r) => r.pickup_group_id === group.id);
  const tags = responsesInPickupGroup.map((r) => ({
    response: r,
    house: houses.find((h) => h.id === r.house_id),
  }));

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => keyDown(e);
    window.addEventListener("keydown", handleKeyDown);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = onClose;

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.onpopstate = null;
    };
  }, []);

  const keyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  const getDirections = (house: TreeTagHouse) => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      house.address.split(",")[0]
    );
  };

  const printPickupGroup = () => {
    if (window.confirm("Printing pickup groups requires the legacy interface. Redirect to troop website?")) {
      window.open(
        "https://troop600.com/tree-tags2/app/driver/print/index.php?id=" + group.id,
        "_blank"
      );
    }
  };

  const deleteGroup = () => {
    if (window.confirm("Are you sure you want to delete this group? This will cause any trees in the group to be unassigned.")) {
      // TODO: Delete pickup group
    }
  };

  const claimPickupGroup = () => {
    // TODO: Assign pickup group to current user
  };

  const unclaimPickupGroup = () => {
    // TODO: Unassign pickup group
  };

  if (!group) {
    return <div>Group not loaded</div>;
  }

  return (
    <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <div className="overlay-header text-center my-3">
        <h3>{group.name}</h3>
      </div>
      <div className="overlay-body container">
        <Card variant="outlined" className="p-3">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                {group.assigned_to === 0 && (
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={claimPickupGroup}
                  >
                    Claim
                  </button>
                )}
                {group.assigned_to === parseInt(userInfo.sub) && (
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={unclaimPickupGroup}
                  >
                    Unclaim
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2"
                  onClick={printPickupGroup}
                >
                  Print
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
            <hr className="my-4" />
            <div className="col-12">
              <StaticMap url={generateStaticMapURL(tags)} />
              <table className={"table " + (theme === "dark" && "text-white")}>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Address</th>
                    <th scope="col"># Tags</th>
                    <th scope="col">Name</th>
                    <th scope="col">Paid?</th>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag, i) => {
                    const r = tag.response
                    const char = i < 26 ? String.fromCharCode(65 + i) : i;
                    if (!tag.house) return null;
                    return (
                      <tr key={r.id}>
                        <td>{char}</td>
                        <td>
                          {shortenAddress(tag.house.address.split(",")[0])}
                          {r.picked_up_status === 0 ? (
                            <IconButton color="primary" aria-label="get directions" component="label" size="small" onClick={() => getDirections(tag.house as TreeTagHouse)}>
                              <DirectionsIcon />
                            </IconButton>
                          ) : (
                            <IconButton disabled color="primary" aria-label="complete" component="label" size="small">
                              <CheckmarkIcon />
                            </IconButton>
                          )}
                        </td>
                        <td>{r.num_tags}</td>
                        <td>{r.name}</td>
                        <td>{r.money >= TREE_TAG_PRICE * r.num_tags ? "Yes" : "No"}</td>
                        <td>{r.pickup_note}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <hr className="my-4" />
            {isAdmin(userInfo) && (
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => setAssignDriverModalOpen(true)}
                  >
                    Reassign
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => setRenameGroupModalOpen(true)}
                  >
                    Rename
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => setCombineGroupModalOpen(true)}
                  >
                    Combine with other group
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={deleteGroup}
                  >
                    Delete group
                  </button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
      <AssignGroupModal
        group={group}
        open={assignDriverModalOpen}
        onClose={() => setAssignDriverModalOpen(false)}
      />
      <RenameGroupModal
        group={group}
        open={renameGroupModalOpen}
        onClose={() => setRenameGroupModalOpen(false)}
      />
      <CombineGroupModal
        group={group}
        open={combineGroupModalOpen}
        onClose={() => setCombineGroupModalOpen(false)}
        theme={theme}
      />
    </div>
  );
};

function generateStaticMapURL(tags: any[]) {
  const overlay = tags.map((tag, i) => ({
    lat: parseFloat(tag.house.lat),
    lng: parseFloat(tag.house.lng),
    color: tag.money >= tag.num_tags * TREE_TAG_PRICE ? "000" : "666",
    char: i < 26 ? String.fromCharCode(65 + i) : "?",
  })).map(
    (point) => `pin-s-${point.char}+${point.color}(${point.lat},${point.lng})`
  ).join(",");
  return "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/" +
    overlay + "/auto/1200x800?access_token=" + MAPBOX_ACCESS_TOKEN;
}

export default GroupInfo;
