import { useState } from "react";
import { Modal, Slider } from "@mui/material";
import Box from "@mui/material/Box";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatter } from "../../utils/Formatter";
import { useSelector } from "react-redux";
import { TreeTagRewardLevel } from "../../store/types";

interface RewardLevelsModalProps {
  open: boolean;
  onClose: () => void;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  height: "80%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

export const getRewardLevelForTotalSales = (totalSales: number, rewardLevels: TreeTagRewardLevel[]) => {
  if (rewardLevels.length === 0) {
    return { level: null, rewardMessage: "" };
  }
  const sortedLevels = rewardLevels.sort((a, b) => a.threshold - b.threshold);
  let level = sortedLevels[0];
  let nextLevel = sortedLevels[1];
  for (let i = 0; i < sortedLevels.length; i++) {
    if (totalSales >= sortedLevels[i].threshold) {
      level = sortedLevels[i];
      nextLevel = sortedLevels[i + 1];
    }
  }
  const salesUntilNextLevel = nextLevel.threshold - totalSales;
  const rewardMessage = salesUntilNextLevel > 0 ?
    `Sell $${salesUntilNextLevel} more to keep ${nextLevel.rate * 100}% of your money and get ${level.draws - nextLevel.draws} extra draws!`
    : "Holy smokes! You've reached the highest level. Keep it up!";
  return { level, rewardMessage };
};

const RewardLevelsModal: React.FC<RewardLevelsModalProps> = ({ open, onClose }) => {
  const [totalSales, setTotalSales] = useState<number>(0);
  const rewardLevels = useSelector((state: any) => state.rewardLevels.data as TreeTagRewardLevel[]);

  const { level } = getRewardLevelForTotalSales(totalSales, rewardLevels);
  if (!level) {
    return null;
  }
  const earnings = totalSales * level.rate;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addRewardLevelsModalLabel">
              Reward Levels
            </h4>
          </div>
          <div className="modal-body">
            <div className="container p-3">
              <p>
                The more you sell, the more you earn! Each level increases the percentage
                of your earnings and gives you extra draws for gift cards.
              </p>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Earnings</TableCell>
                      <TableCell>Entries</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rewardLevels.map((level) => (
                      <TableRow key={level.name}>
                        <TableCell>{level.name}</TableCell>
                        <TableCell>${level.threshold}</TableCell>
                        <TableCell>{level.rate * 100}%</TableCell>
                        <TableCell>{level.draws}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <hr />
              <div className="earnings-calculator">
                <h5>Earnings Calculator</h5>
                <p>
                  Curious how much you can earn? Use the slider below to
                  estimate your earnings.
                </p>
                <Slider
                  value={totalSales}
                  onChange={(e, value) => setTotalSales(value as number)}
                  aria-labelledby="input-slider"
                  min={0}
                  max={3000}
                />
                <p>If you sold {formatter.format(totalSales)}, you would get:</p>
                {level && (
                  <div>
                    <h2>
                      Estimated Earnings{" "}
                      <span
                        className="badge"
                        style={{
                          backgroundColor: level.color,
                        }}
                        title={level.name}
                      >
                        {level.name}
                      </span>
                    </h2>
                    <h1 className="display-4">
                      {formatter.format(earnings)}
                    </h1>
                    <p className="text-muted">
                      🎁 {level.draws} Gift Card Drawings
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RewardLevelsModal;
