import React, { useState } from "react";
import Card from "@mui/material/Card";
import { formatter } from "../../utils/Formatter";
import EfficiencyModal from "./EfficiencyModal";
import { useSelector } from "react-redux";
import { TreeTagHoursEntry, TreeTagResponse } from "../../store/types";

const EfficiencyPage: React.FC = () => {
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  const deleteEntry = (id: number) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      // TODO: Delete hour entry
    }
  };

  const hours = useSelector((state: any) => state.hours.data as TreeTagHoursEntry[]);
  const responses = useSelector((state: any) => state.responses.data as TreeTagResponse[]);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h1 className="fw-normal">Your Efficiency</h1>
          <p className="text-muted">Enter your hours here to compare efficiency between days.</p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddModalOpen(true)}
          >
            Log Hours
          </button>
          <hr />
        </div>
        <div className="col-12">
          <div className="row">
            {hours.map((entry) => {
              const money = responses.filter(
                (response) => new Date(response.created_at).toLocaleDateString('en-US', { timeZone: 'UTC' }) === new Date(entry.date).toLocaleDateString('en-US', { timeZone: 'UTC' })
              ).reduce((acc, response) => acc + response.money, 0);
              return (
                <div className="col-12 p-3" key={entry.id}>
                  <Card variant="outlined" className="p-3">
                    <p className="text-muted">
                      {new Date(entry.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </p>
                    <p className="card-text">
                      <span className="display-4">
                        {formatter.format(money / entry.hours)}
                      </span>
                      {" / hr"}
                    </p>
                    <p>
                      {formatter.format(money)} in {entry.hours} hrs
                    </p>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => deleteEntry(entry.id)}
                    >
                      Delete
                    </button>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <EfficiencyModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onSave={() => { }}
      />
    </div>
  );
};

export default EfficiencyPage;
