import React, { useState, useEffect, useRef } from "react";
import Map, {
  GeolocateControl,
  Source,
  Layer,
  MapProvider,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./QuadMap.css";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Link } from "react-router-dom";
import * as turf from "@turf/turf";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import Card from "@mui/material/Card";
import { TreeTagHouse, TreeTagQuad } from "../../store/types";
import { useSelector } from "react-redux";

interface TreeTagLayer {
  name: string,
  color: string,
  outline: string,
  filter: (house: TreeTagHouse) => boolean;
}

// Layers filter based on time (today, this season, last season) + response
const layers: TreeTagLayer[] = [
  { name: "old_donation", color: "#86689c", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 1 },
  { name: "old_refused", color: "#8f8c56", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 2 },
  { name: "old_no_answer", color: "#8f8c56", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 3 },
  { name: "old_other", color: "#eeeeee", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 4 },
  { name: "old_tag", color: "#609c73", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 5 },
  { name: "new_donation", color: "#6a0dad", outline: "#000000", filter: (house) => !!house.latest_response && house.latest_response.response === 6 },
  { name: "new_refused", color: "#9c0505", outline: "#000000", filter: (house) => !!house.latest_response && house.latest_response.response === 7 },
  { name: "new_no_answer", color: "#ff9100", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 8 },
  { name: "new_no_answer_today", color: "#ff9100", outline: "#000000", filter: (house) => !!house.latest_response && house.latest_response.response === 9 },
  { name: "new_tag", color: "#059c31", outline: "#000000", filter: (house) => !!house.latest_response && house.latest_response.response === 10 },
  { name: "new_other", color: "#000000", outline: "#000000", filter: (house) => !!house.latest_response && house.latest_response.response === 11 },
  { name: "house_generic", color: "#828282", outline: "#e8d900", filter: (house) => !!house.latest_response && house.latest_response.response === 0 },
];

interface QuadMapProps {
  theme: "light" | "dark";
  quad?: TreeTagQuad;
  assignedQuads: number[];
  onHouseClick: (houseId: number) => void;
  houseSelected?: number;
}

const QuadMap: React.FC<QuadMapProps> = (props) => {
  const [legend, setLegend] = useState(!localStorage.getItem("legend"));
  const mapRef = useRef<any>(null);

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);

  const quadIdFilter = props.quad ? [props.quad.id] : props.assignedQuads;
  const housesInQuad = houses.filter((house) => quadIdFilter.includes(house.quad_id));

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [props.theme, props.houseSelected]);

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: MAPBOX_ACCESS_TOKEN,
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: "Search by address...",
      types: "address",
      bbox: [-122.253, 47.561, -122.136, 47.675],
      proximity: {
        longitude: -122.2107,
        latitude: 47.6283,
      },
    });
    geocoder.on("result", (event) => {
      addAddressAndOpen(event.result);
    });
    if (props.quad) {
      localStorage.setItem("quad", props.quad.toString());
    }
  }, [props.quad]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (props.quad?.polygon) {
        try {
          let polygon = turf.polygon([props.quad.polygon]);
          let bbox = turf.bbox(polygon);
          map.fitBounds(bbox, {
            padding: 50,
          });
        } catch (e) { }
      }
    }
  }, [props.quad]);

  const addAddressAndOpen = (feature: any) => {
    const house = houses.find((h) => h.address === feature.place_name);
    if (house) {
      props.onHouseClick(house.id);
    } else {
      // TODO: Add house
      console.log(feature);
    }
  };

  const addrClick = (objs: any, coordinates: any) => {
    const obj = objs.find((obj: any) => {
      return layers.some((layer) => layer.name === obj.source);
    });
    if (obj) {
      var house = JSON.parse(obj.properties["house"]);
      props.onHouseClick(house);
    } else if (navigator.onLine) {
      const labelObj = objs.find((obj: any) => {
        return obj.sourceLayer === "housenum_label";
      });
      if (labelObj) {
        const url =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          coordinates.lng +
          "," +
          coordinates.lat +
          ".json?access_token=" +
          MAPBOX_ACCESS_TOKEN +
          "&types=address";
        fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Network response was not ok.");
            }
          })
          .then((data) => {
            addAddressAndOpen(data.features[0]);
          });
      }
    }
  };

  let quadBorderGeoJSON: GeoJSON.Feature<GeoJSON.Geometry> = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [props.quad ? props.quad.polygon : []],
    },
    properties: null,
  };

  return (
    <div className="map">
      <MapProvider>
        <nav id="menu">
          <div className="btn-group mt-2 me-2 float-end">
            <button
              type="button"
              className="btn btn-light dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {!props.quad ? "All Quads" : `Quad #${props.quad.id}`}
            </button>
            <ul className="dropdown-menu">
              {props.assignedQuads.map((quad) => (
                <Link key={quad} className="dropdown-item" to={"/map/sales/" + quad + "/"}>
                  Quad #{quad}
                </Link>
              ))}
              <Link key={"all"} className="dropdown-item" to={"/map/sales/"}>
                All
              </Link>
            </ul>
          </div>
          {!legend && (
            <button
              className="btn btn-light mt-3 mt-md-2 me-2 float-end"
              onClick={() => {
                setLegend(true);
                localStorage.removeItem("legend");
              }}
              title="Show legend"
            >
              <i className="bi bi-list-ul"></i>
            </button>
          )}
        </nav>
        {legend && (
          <Card className="legend">
            <div
              className="legend-header-close"
              onClick={() => {
                localStorage.setItem("legend", "false");
                setLegend(false);
              }}
            >
              <span> &times; </span>
            </div>
            <h4>Legend</h4>
            <div>
              <span
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #e8d900",
                }}
              ></span>
              Need to visit
            </div>
            <div>
              <span
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #000000",
                }}
              ></span>
              Don't need to visit
            </div>
            <hr className="my-1" />
            <div>
              <span style={{ backgroundColor: "#059c31" }}></span>Tree Tag
            </div>
            <div>
              <span style={{ backgroundColor: "#6a0dad" }}></span>Donation
            </div>
            <div>
              <span style={{ backgroundColor: "#ff9100" }}></span>No Answer
            </div>
            <div>
              <span style={{ backgroundColor: "#9c0505" }}></span>Refused
            </div>
            <div>
              <span style={{ backgroundColor: "#000000" }}></span>Other
            </div>
            <div>
              <span style={{ backgroundColor: "#828282" }}></span>No Data
            </div>
          </Card>
        )}
        <Map
          id="map"
          ref={mapRef}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          initialViewState={{
            longitude: -122.21,
            latitude: 47.63,
            zoom: 13,
          }}
          mapStyle={
            props.theme === "light"
              ? "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr"
              : "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"
          }
          onClick={(e) => {
            const features = mapRef.current?.queryRenderedFeatures(e.point);
            if (features) {
              const displayProperties = [
                "type",
                "properties",
                "id",
                "layer",
                "source",
                "sourceLayer",
                "state",
              ];
              const displayFeatures = features.map((feat: any) => {
                const displayFeat: any = {};
                displayProperties.forEach((prop) => {
                  displayFeat[prop] = feat[prop];
                });
                return displayFeat;
              });
              const clickedCoordinates = e.lngLat;
              addrClick(displayFeatures, clickedCoordinates);
            }
          }}
          maxBounds={MAP_BOUNDS}
        >
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {props.quad?.polygon && (
            <Source id="quad" type="geojson" data={quadBorderGeoJSON}>
              <Layer
                id="quad_border"
                type="line"
                paint={{
                  "line-color": "#f00",
                  "line-width": 3,
                }}
              />
              <Layer
                id="quad_fill"
                type="fill"
                paint={{
                  "fill-color": "#0080ff",
                  "fill-opacity": 0.05,
                }}
              />
            </Source>
          )}
          {layers.map((layer) => {
            let geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
              type: "FeatureCollection",
              features: housesInQuad
                .filter(layer.filter)
                .map((house) => {
                  const houseCoordinates = [house.lat, house.lng];
                  const houseID = house.id;
                  const houseAddress = house.address;
                  return {
                    type: "Feature",
                    properties: {
                      house: house,
                      layerType: layer.name,
                      description: houseAddress,
                      icon: "house",
                      "icon-image": "house",
                      "database-id": houseID,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: houseCoordinates,
                    },
                  };
                }),
            };
            return (
              <Source id={layer.name} type="geojson" data={geoJSON} key={layer.name}>
                <Layer
                  id={layer.name}
                  type="circle"
                  paint={{
                    "circle-color": layer.color,
                    "circle-radius": {
                      base: 1.75,
                      stops: [
                        [12, 2],
                        [22, 180],
                      ],
                    },
                    "circle-stroke-width": 4,
                    "circle-stroke-color": layer.outline,
                  }}
                />
              </Source>
            );
          })}
        </Map>
      </MapProvider>
    </div>
  );
};

export default QuadMap;
