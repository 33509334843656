import { Component } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

interface ResponseChartProps {
  user?: number;
}

interface ResponseChartState {
  yourData: number[];
  allData: number[];
}

const RESPONSES = ["Tree Tag", "Donation", "Refused", "No Answer", "Other"];
const CHART_COLORS = [
  'rgba(75, 192, 192, X)',
  'rgba(153, 102, 255, X)',
  'rgba(255, 99, 132, X)',
  'rgba(255, 206, 86, X)',
  'rgba(54, 162, 235, X)',
];

class ResponseChart extends Component<ResponseChartProps, ResponseChartState> {
  constructor(props: ResponseChartProps) {
    super(props);
    this.state = {
      yourData: RESPONSES.map(() => 0),
      allData: RESPONSES.map(() => 0),
    };
  }

  render() {
    return (
      <div className="p-3">
        <Doughnut
          data={
            {
              labels: RESPONSES,
              datasets: [
                {
                  label: this.props.user ? "Their Responses" : "Your Responses",
                  data: this.state.yourData,
                  backgroundColor: CHART_COLORS.map((color) => color.replace("X", "0.2")),
                  borderColor: CHART_COLORS.map((color) => color.replace("X", "1")),
                  borderWidth: 1,
                },
                {
                  label: "Everyone's Responses",
                  data: this.state.allData,
                  backgroundColor: CHART_COLORS.map((color) => color.replace("X", "0.1")),
                  borderColor: CHART_COLORS.map((color) => color.replace("X", "0.5")),
                  borderWidth: 1,
                }
              ],
            }
          }
          options={
            // do not show legend
            {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                }
              }
            }
          }
        />
      </div>
    );
  }
}

export default ResponseChart;
