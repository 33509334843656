import Map, { Layer, MapProvider, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../quads/QuadMap.css";
import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import { useSelector } from "react-redux";
import { TreeTagQuad } from "../../store/types";

interface LocationEditorProps {
  theme: "light" | "dark";
  coords: [number, number];
  onChange: (coords: [number, number]) => void;
}

const LocationEditor: React.FC<LocationEditorProps> = ({ theme, coords, onChange }) => {
  const [editing, setEditing] = useState(false);
  const [newCoords, setNewCoords] = useState<[number, number]>(coords);
  const mapRef = useRef<any>(null);
  const markerRef = useRef<any>(null);
  const [quadsGeoJson, setQuadsGeoJson] = useState<GeoJSON.FeatureCollection<GeoJSON.Geometry>>({
    type: "FeatureCollection",
    features: [],
  });

  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);

  useEffect(() => {
    const quadsGeoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: quads.map((quad) => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [quad.polygon],
        },
        properties: {
          quad_id: quad.id,
        },
      })),
    };
    setQuadsGeoJson(quadsGeoJson);
  }, [quads]);

  useEffect(() => {
    markerRef.current = new mapboxgl.Marker({
      draggable: false
    })
      .on('dragend', () => {
        let coords = markerRef.current.getLngLat();
        setNewCoords([coords.lng, coords.lat]);
      });

    try {
      markerRef.current.setLngLat(coords);
    } catch (e) {
      markerRef.current.setLngLat([0, 0]);
      console.log(e);
    }
  }, [coords]);

  const original_location: GeoJSON.Feature<GeoJSON.Geometry> = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: coords,
    },
    properties: {},
  };

  return (
    <div className="my-2">
      <div className="row">
        {!editing && (
          <button onClick={() => {
            setEditing(true);
            markerRef.current.setDraggable(true);
          }} className="btn btn-sm btn-outline-secondary">
            Edit Location
          </button>
        )}
        {editing && (
          <button onClick={() => {
            setEditing(false);
            markerRef.current.setDraggable(false);
            onChange(newCoords);
          }} className="btn btn-sm btn-outline-secondary">
            Save
          </button>
        )}
      </div>
      <div className="polygon-map mt-2">
        <MapProvider>
          <Map
            id="polygon-map"
            ref={(ref) => {
              if (ref && !mapRef.current) {
                mapRef.current = ref.getMap();
                markerRef.current.addTo(mapRef.current);
                if (!mapRef.current._controls.some((control: any) => control.modes)) {
                  mapRef.current.on("idle", () => {
                    mapRef.current.resize();
                  });
                }
              }
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{
              longitude: coords[0],
              latitude: coords[1],
              zoom: 15,
            }}
            mapStyle={theme === "light" ?
              "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
              "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
            maxBounds={MAP_BOUNDS}
          >
            <Source key="original_location" id="original_location" type="geojson" data={original_location}>
              <Layer
                id="original_location"
                type="circle"
                paint={{
                  "circle-radius": 5,
                  "circle-color": "#ffffff",
                }}
              />
            </Source>
            {quads.map((quad, i) => (
              <Source key={"quad_border_" + i} id={"quad" + i} type="geojson" data={quadsGeoJson}>
                <Layer
                  id={"quad" + i}
                  type="fill"
                  paint={{
                    "fill-color": "#ff0000",
                    "fill-opacity": 0.2,
                  }}
                />
                <Layer
                  id={"quad-label-" + i}
                  type="symbol"
                  layout={{
                    "text-field": ["get", "quad_id"],
                    "text-size": 12,
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                  }}
                  paint={{
                    "text-color": "#000000",
                  }}
                />
              </Source>
            ))}
          </Map>
        </MapProvider>
      </div>
    </div>
  );
};

export default LocationEditor;