import React from "react";
import Card from "@mui/material/Card";
import { TreeTagPickupGroup, TreeTagResponse } from "../../store/types";
import { useSelector } from "react-redux";

interface PickupGroupCardProps {
  group: TreeTagPickupGroup;
  editable?: boolean;
}

const PickupGroupCard: React.FC<PickupGroupCardProps> = ({ group, editable }) => {
  const treesToPickUp = useSelector((state: any) => state.treesToPickUp.data as TreeTagResponse[]);
  const treesInGroup = treesToPickUp.filter((tree) => tree.pickup_group_id === group.id);

  const numCollected = treesInGroup.filter((tree) => tree.picked_up_status === 1)
    .reduce((acc, tree) => {
      return tree.num_tags + acc;
    }, 0);
  const numIncomplete = treesInGroup.filter((tree) => tree.picked_up_status === 0)
    .reduce((acc, tree) => {
      return tree.num_tags + acc;
    }, 0);
  return (
    <div className="col">
      <Card variant="outlined" className="m-2 p-3">
        <h5 className="card-title">{group.name}</h5>
        <h3>
          {numCollected} / {numCollected + numIncomplete} collected (
          {(
            (numCollected / (numCollected + numIncomplete)) *
            100
          ).toFixed(1)}
          %)
        </h3>
        {/* {editable && (
          <Link
            to={"/quads/" + group.quad_id + "/edit"}
            className="btn btn-link ms-2"
          >
            Unclaim
          </Link>
        )} */}
      </Card>
    </div>
  );
};

export default PickupGroupCard;
