import { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { TreeTagResponse } from "../../store/types";
import { useSelector } from "react-redux";

interface PickupEditorModalProps {
  open: boolean;
  treeTagID: number;
  onClose: () => void;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const PickupEditorModal: React.FC<PickupEditorModalProps> = ({
  open,
  treeTagID,
  onClose,
}) => {
  const [money, setMoney] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");
  const [result, setResult] = useState<number>(0);

  const trees = useSelector((state: any) => state.trees.data as TreeTagResponse[]);
  useEffect(() => {
    const response = trees.find((r) => r.id === treeTagID);
    if (response) {
      setMoney(response.money);
      setNotes(response.pickup_note);
      setResult(response.response);
    }
  }, [treeTagID, trees]);

  const save = () => {
    // TODO: Save the response
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addPickupEditorModalLabel">
              Edit Pickup
            </h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="money">Money: </label>
                <input
                  type="number"
                  className="form-control"
                  value={money}
                  onChange={(e) => setMoney(parseInt(e.target.value))}
                />
              </div>
              <div className="col-md-8 col-sm-12">
                <label htmlFor="status">Status: </label>
                <select
                  className="form-select"
                  id="status"
                  value={result}
                  onChange={(e) => setResult(parseInt(e.target.value))}
                >
                  <option value="0">Incomplete - Need to Return</option>
                  <option value="1">Complete - Picked Up</option>
                  <option value="2">Complete - Not Found</option>
                  <option value="3">Complete - Found, Not Paid</option>
                </select>
              </div>
              <div className="col-12">
                <label htmlFor="notes">Notes: </label>
                <textarea
                  className="form-control"
                  onChange={(e) => setNotes(e.target.value)}
                >
                  {notes}
                </textarea>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PickupEditorModal;
