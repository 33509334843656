import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuadCard from "./QuadCard";
import { TreeTagQuad } from "../../store/types";
import { useSelector } from "react-redux";

const QuadsPage: React.FC = () => {
  const [search, setSearch] = useState("");

  useSelector(console.log)
  const loaded = useSelector((state: any) => !state.quads.isFetching as boolean);
  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);

  if (!loaded) {
    const NUM_PLACEHOLDERS = 20;
    return (
      <div className="container">
        <input
          type="text"
          placeholder="Search"
          className="form-control m-2"
          disabled
        />
        <Link to="/manage/quads/map" className="btn btn-primary m-2">
          View Master Map
        </Link>
        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
          {Array(NUM_PLACEHOLDERS)
            .fill(0)
            .map((_, i) => (
              <div className="col" key={i}>
                <div className="card m-2">
                  <div className="card-body">
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder col-8"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder col-8"></span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <input
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value.toLowerCase())}
        className="form-control m-2"
      />
      <Link to="/manage/quads/map" className="btn btn-primary m-2">
        View Master Map
      </Link>
      <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
        {quads.map((quad) => {
          if (search) {
            const propsToSearch: (keyof TreeTagQuad)[] = ["assigned_to", "id"];
            let found = false;
            for (const prop of propsToSearch) {
              if (quad[prop].toString().toLowerCase().includes(search)) {
                found = true;
              }
            }
            if (!found) {
              return null;
            }
          }
          return <QuadCard key={"quad_" + quad.id} quad={quad} editable />;
        })}
      </div>
    </div>
  );
};

export default QuadsPage;
