import { useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import SelectPickupGroup from "./SelectPickupGroup";
import { TreeTagPickupGroup } from "../../store/types";

interface CombineGroupModalProps {
  open: boolean;
  onClose: () => void;
  group: TreeTagPickupGroup;
  theme: any;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const CombineGroupModal: React.FC<CombineGroupModalProps> = ({
  open,
  onClose,
  group,
  theme,
}) => {
  const [other_id, setOtherId] = useState<number>(-1);

  const save = () => {
    // TODO: Combine pickup groups
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addCombineGroupModalLabel">
              Combine With Other Group
            </h4>
          </div>
          <div className="modal-body" style={{ height: "50vh" }}>
            <SelectPickupGroup
              theme={theme}
              onGroupSelect={(id) => {
                setOtherId(id);
              }}
            />
            <div className="mt-3">
              <b>
                Current Group: #{group.id} ({group.name})
              </b>
              <br />
              {other_id !== -1 ? (
                <b>Selected Group: #{other_id}</b>
              ) : (
                <b>No Group Selected</b>
              )}
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
              disabled={other_id === -1}
            >
              Combine
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CombineGroupModal;
