import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { Close as CloseIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

interface FAQ {
  question: string;
  answer: string;
}

interface EditFAQModalProps {
  show: boolean;
  handleClose: () => void;
  faqs: FAQ[];
  saveFAQs: (faqs: FAQ[]) => void;
}

const EditFAQModal: React.FC<EditFAQModalProps> = ({ show, handleClose, faqs, saveFAQs }) => {
  const [faqList, setFaqList] = useState<FAQ[]>(faqs);

  const handleInputChange = (index: number, field: string, value: string) => {
    const newFaqList = [...faqList];
    newFaqList[index] = { ...newFaqList[index], [field]: value };
    setFaqList(newFaqList);
  };

  const handleAddFAQ = () => {
    setFaqList([...faqList, { question: '', answer: '' }]);
  };

  const handleRemoveFAQ = (index: number) => {
    const newFaqList = faqList.filter((_, i) => i !== index);
    setFaqList(newFaqList);
  };

  const handleSave = () => {
    saveFAQs(faqList);
    handleClose();
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={{ maxWidth: 600, bgcolor: 'background.paper', p: 4, mx: 'auto', mt: '10%' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Edit FAQs</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto', paddingY: 2, mt: 2 }}>
          {faqList.map((faq, index) => (
            <Box key={index} mb={2} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <TextField
                  label="Question"
                  variant="outlined"
                  fullWidth
                  value={faq.question}
                  onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Answer"
                  variant="outlined"
                  fullWidth
                  multiline
                  value={faq.answer}
                  onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                  sx={{ mb: 2 }}
                />
              </Box>
              <IconButton color="error" onClick={() => handleRemoveFAQ(index)} sx={{ ml: 2 }}>
                <DeleteIcon />
              </IconButton>
              <hr />
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddFAQ}>
            Add FAQ
          </Button>
          <Box>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mr: 2 }}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditFAQModal;