import { LngLatBoundsLike } from "mapbox-gl";

/**
 * Global bounds for all maps.
 */
export const MAP_BOUNDS: LngLatBoundsLike = [
  [-122.725771, 47.334523], // SW point
  [-121.891901, 47.864922] // NE point
];

export const API_URL = "https://tree-tags-api.troop600.com";
export const PUBLIC_SALES_SITE = "https://recyclemytree.org"
export const LOGIN_URL = API_URL + "/auth/google";
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoia2FlZGVuYiIsImEiOiJja2FyMmRyeHcwZ3dmMnFwbGhuZTc2MXgyIn0.vmW7QzXVTA1D879Fs3e0xA";

