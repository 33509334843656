import Map, {
  GeolocateControl,
  Source,
  Layer,
  MapProvider,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import "../quads/QuadMap.css";
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from "../../utils/Params";
import Card from "@mui/material/Card";
import { TreeTagHouse, TreeTagPickupGroup, TreeTagResponse } from "../../store/types";
import { getUserInfo } from "../../utils/API";
import { TREE_TAG_PRICE } from "../../utils/Formatter";
import { useSelector } from "react-redux";

const userInfo = getUserInfo();

const LAYERS = [
  { name: "tag_no_pickup_group_id", color: "#ff00ee", outline: "#ff0000", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id === null; } },
  { name: "tag_yours_paid_completed", color: "#059c31", outline: "#000000", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && paid(tree) && yours(tree) && isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_paid_todo", color: "#059c31", outline: "#ffff00", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && paid(tree) && yours(tree) && !isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_unpaid_completed", color: "#ffc000", outline: "#000000", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !paid(tree) && yours(tree) && isCompleted(tree.picked_up_status); } },
  { name: "tag_yours_unpaid_todo", color: "#ffc000", outline: "#ffff00", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !paid(tree) && yours(tree) && !isCompleted(tree.picked_up_status); } },
  { name: "tag_unclaimed_completed", color: "#ff0000", outline: "#000000", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !driverAssigned(pickupGroup) && isCompleted(tree.picked_up_status); } },
  { name: "tag_unclaimed_todo", color: "#ff0000", outline: "#ffff00", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !driverAssigned(pickupGroup) && !isCompleted(tree.picked_up_status); } },
  { name: "tag_other_completed", color: "#000000", outline: "#000000", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !yours(tree) && driverAssigned(pickupGroup) && isCompleted(tree.picked_up_status); } },
  { name: "tag_other_todo", color: "#000000", outline: "#ffff00", filter: (tree: TreeTagResponse, pickupGroup: TreeTagPickupGroup) => { return tree.pickup_group_id !== null && !yours(tree) && driverAssigned(pickupGroup) && !isCompleted(tree.picked_up_status); } },
];

function paid(tree: TreeTagResponse) {
  return tree.money >= TREE_TAG_PRICE * tree.num_tags;
}

function yours(tree: TreeTagResponse) {
  return tree.scout === parseInt(userInfo.sub);
}

function driverAssigned(pickupGroup: TreeTagPickupGroup) {
  return pickupGroup.assigned_to !== 0;
}

interface PickupMapProps {
  theme: "light" | "dark";
  trees: TreeTagResponse[];
  groups: TreeTagPickupGroup[];
  onTreeClick: (tree: TreeTagResponse) => void;
  treeSelected?: TreeTagResponse | null;
  groupSelected?: TreeTagPickupGroup | null;
  onGroupClick: (group: TreeTagPickupGroup) => void;
}

interface DriverLocation {
  id: number;
  user_id: number;
  username: string;
  name: string;
  lat: number;
  lng: number;
  timestamp: string;
}

const PickupMap: React.FC<PickupMapProps> = (props) => {
  const [legend, setLegend] = useState(!localStorage.getItem("legend"));
  const [onlyYourTrees, setOnlyYourTrees] = useState(false);
  const [driverLocations, setDriverLocations] = useState<DriverLocation[]>([]);
  const [locationEnabled, setLocationEnabled] = useState(true);
  const mapRef = useRef<any>(null);

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const pickup_groups = useSelector((state: any) => state.pickup_groups.data as TreeTagPickupGroup[]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(() => { }, (err) => {
        if (err && err.code === err.PERMISSION_DENIED) {
          setLocationEnabled(false);
        }
      });
    }
    loadDriverLocations();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, [props.treeSelected, props.groupSelected]);

  const loadDriverLocations = () => {
    // TODO: Load driver locations
  };

  const logLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        // TODO: Log location of current driver
      });
    }
  };

  const addrClick = (objs: any, coordinates: any) => {
    const treeObj = objs.find((obj: any) => {
      return LAYERS.some((layer) => layer.name === obj.source);
    });
    const groupObj = objs.find((obj: any) => {
      return obj.source.includes("group_");
    });
    if (treeObj) {
      const tree = JSON.parse(treeObj.properties["tree"]);
      props.onTreeClick(tree);
    } else if (groupObj) {
      const group = JSON.parse(groupObj.properties["group"]);
      props.onGroupClick(group);
    }
  };

  if (!locationEnabled) {
    return (
      <div className="map p-5">
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading">Location services disabled</h4>
          <p>
            Location services are required to use the map. Please enable location services in your browser settings and refresh the page.
          </p>
          <button className="btn btn-warning" onClick={() => setLocationEnabled(true)}>
            Continue Anyways
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="map">
      <MapProvider>
        <nav id="menu">
          <button
            className="btn btn-light mt-3 mt-md-2 me-2 float-end"
            onClick={() => {
              if (onlyYourTrees) {
                toast("Showing all trees", { icon: "🎄", id: "show-all" });
              } else {
                toast("Showing only your trees", { icon: "🎄", id: "show-all" });
              }
              setOnlyYourTrees(!onlyYourTrees);
            }}
            title="Show only your trees"
          >
            <i className="bi bi-person"></i>
          </button>
          {!legend && (
            <button
              className="btn btn-light mt-3 mt-md-2 me-2 float-end"
              onClick={() => {
                setLegend(true);
                localStorage.removeItem("legend");
              }}
              title="Show legend"
            >
              <i className="bi bi-list-ul"></i>
            </button>
          )}
        </nav>
        {legend && (
          <Card className="legend">
            <div
              className="legend-header-close"
              onClick={() => {
                localStorage.setItem("legend", "false");
                setLegend(false);
              }}
            >
              <span> &times; </span>
            </div>
            <h4>Legend</h4>
            <div>
              <span
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "1px solid rgb(0, 0, 0)",
                }}
              ></span>
              Completed
            </div>
            <div>
              <span
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  border: "1px solid rgb(255, 255, 0)",
                }}
              ></span>
              Not Completed
            </div>
            <hr className="my-1" />
            <div>
              <span
                style={{
                  backgroundColor: "#059c31",
                  border: "1px solid rgb(5, 156, 49)",
                }}
              ></span>
              Your Tree (Paid)
            </div>
            <div>
              <span
                style={{
                  backgroundColor: "#ffc000",
                  border: "1px solid rgb(255, 192, 0)",
                }}
              ></span>
              Your Tree (Not Paid)
            </div>
            {!onlyYourTrees && (
              <div>
                <span
                  style={{
                    backgroundColor: "rgb(255, 0, 0)",
                    border: "1px solid rgb(255, 0, 0)",
                  }}
                ></span>
                Unclaimed Tree
              </div>
            )}
            {!onlyYourTrees && (
              <div>
                <span
                  style={{
                    backgroundColor: "rgb(0, 0, 0)",
                    border: "1px solid rgb(0, 0, 0)",
                  }}
                ></span>
                Other Driver's Tree
              </div>
            )}
          </Card>
        )}
        <Map
          id="map"
          ref={mapRef}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          initialViewState={{
            longitude: -122.21,
            latitude: 47.63,
            zoom: 13,
          }}
          mapStyle={props.theme === "light" ?
            "mapbox://styles/kaedenb/ckwr9608q2wnn14o5ku9ns8jr" :
            "mapbox://styles/kaedenb/ckxdmq7so218214o15s0gci94"}
          onClick={(e) => {
            const features = mapRef.current?.queryRenderedFeatures(e.point);
            if (features) {
              const displayProperties = [
                "type",
                "properties",
                "id",
                "layer",
                "source",
                "sourceLayer",
                "state",
              ];
              const displayFeatures = features.map((feat: any) => {
                const displayFeat: any = {};
                displayProperties.forEach((prop) => {
                  displayFeat[prop] = feat[prop];
                });
                return displayFeat;
              });
              const clickedCoordinates = e.lngLat;
              addrClick(displayFeatures, clickedCoordinates);
            }
          }}
          maxBounds={MAP_BOUNDS}
        >
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {LAYERS.filter((layer) => {
            if (onlyYourTrees) {
              return layer.name.includes("yours");
            } else {
              return true;
            }
          }).map((layer) => {
            let geoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
              type: "FeatureCollection",
              features: props.trees.map((tree) => {
                const pickupGroup = pickup_groups.find((group) => group.id === tree.pickup_group_id);
                const house = houses.find((house) => house.id === tree.house_id);
                return {
                  'tree': tree,
                  'house': house,
                  'pickupGroup': pickupGroup,
                }
              })
                .filter(({ tree, pickupGroup, house }) => {
                  return pickupGroup && house && layer.filter(tree, pickupGroup)
                })
                .map(({ tree, pickupGroup, house }) => {
                  house = house as TreeTagHouse; // we know it's not undefined
                  const treeCoordinates = [house.lat, house.lng];
                  const treeID = tree.id;
                  const treeAddress = house.address;
                  return {
                    type: "Feature",
                    properties: {
                      tree: tree,
                      description: treeAddress,
                      icon: "tree",
                      "icon-image": "tree",
                      id: treeID,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: treeCoordinates,
                    },
                  };
                }),
            };
            return (
              <Source
                id={layer.name}
                type="geojson"
                data={geoJSON}
                key={layer.name}
              >
                <Layer
                  id={layer.name}
                  type="circle"
                  paint={{
                    "circle-color": layer.color,
                    "circle-radius": {
                      'base': 2.75,
                      'stops': [
                        [12, 5],
                        [22, 180]
                      ]
                    },
                    "circle-stroke-width": 4,
                    "circle-stroke-color": layer.outline,
                  }}
                />
              </Source>
            );
          })}
          {props.groups.filter(
            (pickup_group_id) => pickup_group_id.name !== "Quad #0" &&
              (onlyYourTrees
                ? pickup_group_id.assigned_to === -1
                : true)
          )
            .map((pickup_group_id) => {
              let color = "#555555"; // Other driver
              if (pickup_group_id.assigned_to === parseInt(userInfo.sub)) {
                color = "#059c31";
              } else if (pickup_group_id.assigned_to === 0) {
                color = "#ff0000";
              }
              const housesInGroup = houses.filter((h)=> h.quad_id === pickup_group_id.id);
              const minLat = Math.min(...housesInGroup.map((house) => house.lat));
              const maxLat = Math.max(...housesInGroup.map((house) => house.lat));
              const minLng = Math.min(...housesInGroup.map((house) => house.lng));
              const maxLng = Math.max(...housesInGroup.map((house) => house.lng));
              const BUFFER_SIZE = 0.0004;
              const rectangle = {
                type: "Feature",
                properties: {
                  group: pickup_group_id,
                  color: color,
                  id: pickup_group_id.id,
                },
                geometry: {
                  type: "Polygon",
                  coordinates: [
                    [
                      [minLat - BUFFER_SIZE, minLng - BUFFER_SIZE],
                      [minLat - BUFFER_SIZE, maxLng + BUFFER_SIZE],
                      [maxLat + BUFFER_SIZE, maxLng + BUFFER_SIZE],
                      [maxLat + BUFFER_SIZE, minLng - BUFFER_SIZE],
                      [minLat - BUFFER_SIZE, minLng - BUFFER_SIZE],
                    ],
                  ],
                },
              };
              return rectangle;
            })
            .map((feature: any) => {
              return (
                <Source
                  id={"group_" + feature.properties.id}
                  type="geojson"
                  data={feature}
                  key={feature.properties.id}
                >
                  <Layer
                    id={"group_" + feature.properties.id}
                    type="fill"
                    paint={{
                      "fill-color": feature.properties.color,
                      "fill-opacity": 0.3,
                    }}
                  />
                </Source>
              );
            })}
          <Source id="driver_locations" type="geojson" data={
            {
              type: "FeatureCollection",
              features: driverLocations.map((driver: DriverLocation) => {
                return {
                  type: "Feature",
                  properties: {
                    description:
                      driver.username.toLowerCase() +
                      "\n" +
                      relativeTime(driver.timestamp),
                    "database-id": driver.id,
                    timestamp: driver.timestamp,
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [driver.lng, driver.lat],
                  },
                };
              })
            }
          }>
            <Layer
              id="driver_locations"
              type="symbol"
              layout={{
                "text-field": ["get", "description"],
                "text-variable-anchor": ["top", "bottom", "left", "right"],
                "text-radial-offset": 1,
                "text-justify": "auto",
                "icon-image": props.theme === "dark" ? "truck-light" : "truck-dark",
                "icon-size": 0.25,
              }}
              paint={{
                "text-color": props.theme === "dark" ? "#fff" : "#000",
              }}
            />
          </Source>
        </Map>
      </MapProvider>
    </div>
  );
};

function relativeTime(time: string) {
  const d = new Date(time);
  d.setHours(d.getHours() + 8);
  const diff = ((new Date().getTime() - d.getTime()) / 1000);
  if (diff < 60) {
    return "just now";
  } else if (diff < 3600) {
    return Math.floor(diff / 60) + "m ago";
  }
  return Math.floor(diff / 3600) + "h ago";
}

function isCompleted(status: number) {
  return status !== 0;
}

export default PickupMap;
