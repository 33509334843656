import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import { PUBLIC_SALES_SITE } from "../../utils/Params";
import { getUserInfo } from "../../utils/API";

const userInfo = getUserInfo();

const QrCodePage: React.FC = () => {
  const [type, setType] = useState<"tree-tag" | "donation">("tree-tag");
  const scoutId = getUserInfo().scoutId;

  const getURL = () => {
    if (type === "tree-tag") {
      return `${PUBLIC_SALES_SITE}/tree-tag?scoutId=${scoutId}`;
    } else if (type === "donation") {
      return `${PUBLIC_SALES_SITE}/donate?scoutId=${scoutId}`;
    } else {
      return PUBLIC_SALES_SITE;
    }
  };

  const shareURL = () => {
    if (navigator.share) {
      navigator.share({
        title: type === "tree-tag" ? "Recycle My Tree" : "Make a Donation",
        text: "Scan this QR code to " + (type === "tree-tag" ? "order a tree tag from" : "make a donation to") + " Troop 600.",
        url: getURL(),
      })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      toast.error("Sharing is not supported on this device.", { id: "error" });
    }
  };

  return (
    <div className="text-center pt-2 mb-5">
      <div className="w-100">
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={(event, newValue) => {
            if (newValue) {
              setType(newValue);
            }
          }}
          aria-label="Platform"
        >
          <ToggleButton value="tree-tag" aria-label="Tree Tag">
            Tree Tag
          </ToggleButton>
          <ToggleButton value="donation" aria-label="Donation">
            Donation
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="h-auto mx-auto w-100 p-5 bg-light rounded my-2" style={{ maxWidth: "400px" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={getURL()}
          viewBox={`0 0 256 256`}
        />
      </div>
      <p className="text-muted mt-0">Your Scout ID is <pre className="d-inline">
        {userInfo().scout_id}
      </pre></p>
      <button className="btn btn-primary" onClick={shareURL}>Share</button>
    </div>
  );
};

export default QrCodePage;
