import { configureStore } from "@reduxjs/toolkit";
import { responsesReducer, housesReducer, quadsReducer, usersReducer, hoursReducer, rewardLevelsReducer, goalsReducer, manualEntriesReducer, pickupGroupsReducer, treesReducer, scoutStatsReducer, yearStatsReducer, siteConfigReducer } from "./slices";

const store = configureStore({
  reducer: {
    responses: responsesReducer,
    houses: housesReducer,
    quads: quadsReducer,
    users: usersReducer,
    hours: hoursReducer,
    rewardLevels: rewardLevelsReducer,
    goals: goalsReducer,
    manualEntries: manualEntriesReducer,
    pickupGroups: pickupGroupsReducer,
    scoutStats: scoutStatsReducer,
    yearStats: yearStatsReducer,
    trees: treesReducer,
    siteConfig: siteConfigReducer,
  },
});

export default store;
