import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import DirectionsIcon from '@mui/icons-material/Directions';
import IconButton from "@mui/material/IconButton";
import MoveTagToGroupModal from "./MoveTagToGroupModal";
import { formatter, TREE_TAG_PRICE } from "../../utils/Formatter";
import { shortenAddress } from "../quads/HouseInfo";
import { Link } from "react-router-dom";
import { TreeTagHouse, TreeTagResponse } from "../../store/types";
import { useSelector } from "react-redux";
import { getUserInfo, isAdmin } from "../../utils/API";

interface TagInfoProps {
  tree: TreeTagResponse;
  onClose: () => void;
  onUpdate: () => void;
  theme: "light" | "dark";
}

interface TagState {
  result: number;
  money: number;
}

const userInfo = getUserInfo();

const TagInfo: React.FC<TagInfoProps> = ({ tree, onClose, onUpdate, theme }) => {
  const [history, setHistory] = useState<any[]>([]);
  const [tagState, setTagState] = useState<TagState>({
    result: tree.picked_up_status,
    money: tree.num_tags * TREE_TAG_PRICE - tree.money || 0,
  });
  const [moveTagModalOpen, setMoveTagModalOpen] = useState(false);

  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const house = houses.find((h) => h.id === tree.house_id);

  const keyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    window.addEventListener("keydown", keyDown, false);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = onClose;

    return () => {
      window.removeEventListener("keydown", keyDown, false);
      window.onpopstate = null;
    };
  }, [keyDown, onClose]);

  const saveResponse = () => {
    // TODO: Save response
  };

  const moveToNewPickupGroup = () => {
    // TODO: Update pickup group id for response
  };


  if (!tree) {
    return <div>Tree not loaded</div>;
  }

  if (!house) {
    return <div>House not found</div>;
  }

  const getDirections = () => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
      house.address.split(",")[0]
    );
  };

  if (!house.address) {
    console.log(tree);
    return <div>Tree does not have an address</div>;
  }

  const owed = (tree.num_tags * TREE_TAG_PRICE) - tree.money;
  const shortAddress = shortenAddress(house.address.split(",")[0]);

  return (
    <div style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <div className="overlay-header text-center my-3">
        <h3>
          {shortAddress.toUpperCase()}
          <IconButton color="primary" aria-label="get directions" component="label" onClick={getDirections}>
            <DirectionsIcon />
          </IconButton>
        </h3>
      </div>
      <div className="overlay-body container">
        <Card variant="outlined" className="p-3">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="addr_result">Status</label>
                <select
                  className="form-select"
                  id="addr_result"
                  value={tagState.result}
                  onChange={(e) => {
                    setTagState({
                      ...tagState,
                      result: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value="0">Incomplete - Need to Return</option>
                  <option value="1">Complete - Picked Up</option>
                  <option value="2">Complete - Not Found</option>
                  <option value="3">Complete - Found, Not Paid</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="addr_money">Money Collected Today</label>
                <input
                  type="number"
                  className="form-control"
                  id="addr_money"
                  placeholder="$0.00"
                  value={tagState.money}
                  onChange={(e) => {
                    setTagState({
                      ...tagState,
                      money: parseFloat(e.target.value),
                    });
                  }}
                />
              </div>
              {owed > 0 && tagState.money < owed && (
                <div className="alert alert-warning mt-2" role="alert">
                  <strong>Warning!</strong> You are collecting less than the
                  amount owed. This house has been recorded as having ordered {tree.num_tags} tag{
                    tree.num_tags === 1 ? "" : "s"
                  }, and has paid {formatter.format(tree.money)} so far. If no money is found on the tree,
                  we do not recommend that you pick it up.
                </div>
              )}
            </div>
            <div className="col-12">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2"
                  onClick={saveResponse}
                >
                  Report result
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2 me-2"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
            <hr className="my-4" />
            {isAdmin(userInfo) && (
              <div className="col-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={() => setMoveTagModalOpen(true)}
                  >
                    Move to other group
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary mt-2 me-2"
                    onClick={moveToNewPickupGroup}
                  >
                    Move to new group
                  </button>
                  <Link
                    className="btn btn-outline-warning mt-2 me-2 w-xs-100"
                    to={"/houses/" + tree.house_id}
                  >
                    Edit house
                  </Link>
                </div>
              </div>
            )}
          </div>
        </Card>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Card variant="outlined" className="m-2 p-3">
              <div className="row">
                <div className="col-12">
                  <h4>Contact Info</h4>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h5>{tree.name}</h5>
                    </div>
                    <div className="col-12">
                      <h6>{formatPhone(tree.phone)}</h6>
                    </div>
                    <div className="col-12">
                      <h6>{formatEmail(tree.email)}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-6 col-sm-12">
            <Card variant="outlined" className="mt-2 p-3">
              <div className="row">
                <div className="col-12">
                  <h4>Tree Info</h4>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h6>
                        Sold by {tree.scout} on {new Date(tree.created_at * 1000).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                      </h6>
                    </div>
                    <div className="col-12">
                      <h6>
                        {"🎄".repeat(tree.num_tags)}{" "}
                        {tree.num_tags} tree{
                          tree.num_tags === 1 ? "" : "s"
                        } expected
                      </h6>
                    </div>
                    <div className="col-12">
                      {owed > 0 ? (
                        <h6>
                          {formatter.format(owed)} expected for {tree.num_tags} tree{
                            tree.num_tags === 1 ? "" : "s"
                          }, not paid yet.
                        </h6>
                      ) : (
                        <h6>
                          No money expected, already paid.
                        </h6>
                      )}
                    </div>
                    <div className="col-12">
                      <p className="text-muted">Notes:</p>
                      <p className="text-muted">{tree.pickup_note}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <MoveTagToGroupModal
        tag={tree}
        open={moveTagModalOpen}
        onClose={() => setMoveTagModalOpen(false)}
        onUpdate={onUpdate}
        theme={theme}
      />
    </div>
  );
};

export function formatPhone(phone: string) {
  if (!phone) return "";
  phone = phone.replace(/\D/g, "");
  if (phone.length === 11 && phone[0] === "1") {
    phone = phone.slice(1);
  }
  if (phone.length === 10) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else {
    return phone;
  }
}

export function formatEmail(email: string) {
  if (!email?.includes("@")) return "";
  return email.toLocaleLowerCase();
}

export default TagInfo;