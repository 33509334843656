import React, { useState } from "react";
import Card from "@mui/material/Card";
import { formatter } from "../../utils/Formatter";
import ManualEntryModal from "./ManualEntryModal";
import { TreeTagManualEntry } from "../../store/types";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/API";

const userInfo = getUserInfo();

const ManualEntries: React.FC = () => {
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  const manualEntries = useSelector((state: any) => state.manual_entries.data as TreeTagManualEntry[]);
  const myEntries = manualEntries.filter((entry: TreeTagManualEntry) => entry.user_id === parseInt(userInfo.sub));

  const deleteEntry = (id: number) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      // TODO: Delete manual entry
    }
  };

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12">
          <h1>Manual Entries</h1>
          <p>
            If you forgot to record a donation or are having issues with the
            map, record donations manually here.
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setAddModalOpen(true)}
          >
            Add Manual Entry
          </button>
          <hr />
        </div>
        <div className="col-12">
          <div className="row">
            {myEntries.map((entry) => (
              <div className="col-12 p-3" key={entry.id}>
                <Card variant="outlined" className="p-3">
                  <h5 className="card-title">{formatter.format(entry.amount)}</h5>
                  <p className="card-text">
                    {new Date(entry.created_at * 1000).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                  </p>
                  <p className="card-text">{entry.note}</p>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => deleteEntry(entry.id)}
                  >
                    Delete
                  </button>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ManualEntryModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
      />
    </div>
  );
};

export default ManualEntries;
