import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TreeTagHouse, TreeTagResponse, User } from "../../store/types";
import { useSelector } from "react-redux";

interface TagListProps {
  theme: "light" | "dark";
}

interface Tag {
  response: TreeTagResponse;
  house: TreeTagHouse | undefined;
  scoutName: string;
  creatorName: string;
}

function matchesSearch(tag: Tag, searchTerm: string) {
  const fields = [
    tag.house?.address,
    tag.response.name,
    tag.response.email,
    tag.response.phone,
    tag.scoutName,
    tag.creatorName,
  ];
  return fields.some((field) => field && field.toLowerCase().includes(searchTerm));
}

export const interpretResponse = (response: number) => {
  const responses = ["Tree Tag", "Donation", "Refused", "No Answer", "Other"];
  return responses[response];
};

const TagList: React.FC<TagListProps> = ({ theme }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allTypes, setAllTypes] = useState(false);
  const [onlyWithComment, setOnlyWithComment] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);

  const responses = useSelector((state: any) => state.responses.data as TreeTagResponse[]);
  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const users = useSelector((state: any) => state.users.data as User[]);

  useEffect(() => {
    const tags = responses.filter((r) => {
      if (allTypes) {
        return true;
      }
      return r.response === 0;
    }).map((r) => {
      const house = houses.find((h) => h.id === r.house_id);
      const scoutName = users.find((u) => u.id === r.scout);
      const creatorName = users.find((u) => u.id === r.created_by);
      return {
        response: r,
        house: house,
        scoutName: scoutName ? scoutName.name : "Unknown",
        creatorName: creatorName ? creatorName.name : "Unknown",
      };
    });
    setTags(tags);
  }, [responses, allTypes, houses, users]);

  const getURL = () => {
    let url = "list/";
    if (allTypes && !onlyWithComment) {
      url += "?all_types=true";
    } else if (allTypes && onlyWithComment) {
      url += "?all_types=true&comment_only=true";
    } else if (!allTypes && onlyWithComment) {
      url += "?comment_only=true";
    }
    return url;
  };


  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="col-12">
          <h1 className="fw-normal">Your {allTypes ? "Responses" : "Tags"}</h1>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
            />
          </div>
          <button
            className="btn btn-outline-primary me-2"
            onClick={() => {
              setAllTypes(!allTypes);
            }}
          >
            {allTypes ? "Show Only Tags" : "Show All Types"}
          </button>
          <hr />
          <div className="mw-100" style={{ overflow: "auto" }}>
            {tags.length > 0 ? (
              <table className={"table " + (theme === "dark" && "text-white")}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>House</th>
                    <th>Date</th>
                    <th>Response</th>
                    <th># Tags</th>
                    <th>Name</th>
                    <th>Money</th>
                    <th>
                      Comment{" "}
                      <span className="text-muted small">
                        (<button className="btn btn-link p-0" onClick={() => setOnlyWithComment(!onlyWithComment)}>
                          {onlyWithComment ? "clear filter" : "show only"}
                        </button>)
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag) => {
                    if (searchTerm && !matchesSearch(tag, searchTerm)) {
                      return null;
                    }
                    const r = tag.response;
                    return (
                      <tr key={r.id}>
                        <td>{r.id}</td>
                        <td>
                          <Link to={`/map/sales/${r.quad_id}?house=${r.house_id}`}>
                            {r.house_id}
                          </Link>
                        </td>
                        <td>{new Date(r.created_at * 1000).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
                        <td>{interpretResponse(r.response)}</td>
                        <td>{r.num_tags}</td>
                        <td>{r.name}</td>
                        <td>{r.money}</td>
                        <td>{(r.pickup_note || "") + (r.stripe_session_id ? " (online order)" : "")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                {allTypes ? (
                  <p className="text-muted">No responses found.</p>
                ) : (
                  <p className="text-muted">No tags found.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagList;
