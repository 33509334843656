import React from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { shortenAddress } from "../quads/HouseInfo";
import LocationEditor from "./LocationEditor";
import { useSelector } from "react-redux";
import { TreeTagHouse } from "../../store/types";

interface HouseEditorProps {
  theme: "light" | "dark";
}

const HouseEditor: React.FC<HouseEditorProps> = ({ theme }) => {
  const { id } = useParams<{ id: string }>();
  const houses = useSelector((state: any) => state.houses.data as TreeTagHouse[]);
  const house = houses.find((house) => house.id === parseInt(id || '0'));

  const deleteHouse = () => {
    let input = window.prompt(
      "Are you sure you want to delete this house? This cannot be undone.\nType 'delete house' to confirm."
    );
    if (input === "delete house") {
      // TODO: Delete house
    } else {
      toast.error("House not deleted", { id: "error" });
    }
  };

  const changeQuad = () => {
    const newQuad = parseInt(window.prompt("Enter new quad ID") || "");
    if (!isNaN(newQuad)) {
      // TODO: Update quad id
    }
  };

  const changeLocation = (newCoords: [number, number]) => {
    // TODO: Update lat/lng for house
  };

  if (!house) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-5 fw-bold">House not found</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="display-5 fw-bold">{shortenAddress(house.address.split(",")[0])}</h1>
        </div>
        <div className="col-12 text-center">
          <p>
            <code>
              {JSON.stringify(house)}
            </code>
          </p>
          <p>
            <strong>Quad:</strong> <Link to={"/map/sales/" + house.quad_id}>{house.quad_id}</Link>
            <button
              className="btn btn-sm btn-outline-secondary ms-2"
              onClick={changeQuad}
            >
              Change
            </button>
          </p>
          <p>
            <strong>Coordinates:</strong> [{house.lat}, {house.lng}]
          </p>
          <LocationEditor
            theme={theme}
            coords={[house.lat, house.lng]}
            onChange={changeLocation}
          />
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={deleteHouse}
          >
            Delete House
          </button>
        </div>
      </div>
    </div>
  );
};

export default HouseEditor;
