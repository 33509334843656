import React, { useState } from "react";
import UserTagify from "../quads/UserTagify";

const MoveResponses: React.FC = () => {
  const [userSelected, setUserSelected] = useState<number>(-1);

  const moveResponses = () => {
    // TODO: Move responses from one user to another
  };

  return (
    <div className="container mt-2 mb-5">
      <h1 className="fw-normal h3">Move Responses</h1>
      <p className="text-muted mb-1">
        If you have been entering responses for a different user, you can move them to their account here.
      </p>
      <p className="text-muted mb-1">
        Select a user to move responses to:
      </p>
      <UserTagify
        onChange={(ids) => ids.length > 0 ? setUserSelected(ids[0]) : -1}
        defaultValue={[userSelected]}
        maxTags={1}
      />
      <button
        className="btn btn-primary mt-2"
        disabled={userSelected === -1}
        onClick={moveResponses}
      >
        Move Responses
      </button>
    </div>
  );
};

export default MoveResponses;
