import React, { useState } from "react";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";

interface ManualEntryModalProps {
  open: boolean;
  onClose: () => void;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: "80%",
};

const ManualEntryModal: React.FC<ManualEntryModalProps> = ({ open, onClose }) => {
  const [money, setMoney] = useState(0);
  const [notes, setNotes] = useState("");

  const save = () => {
    // TODO: Save manual entry
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="addManualEntryModalLabel">
              Add Manual Entry
            </h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label htmlFor="money">Money: </label>
                <input
                  type="number"
                  className="form-control"
                  value={money}
                  onChange={(e) => setMoney(parseInt(e.target.value))}
                />
              </div>
              <div className="col-12">
                <label htmlFor="notes">Notes: </label>
                <textarea
                  className="form-control"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <button
              type="button"
              className="btn btn-success"
              onClick={save}
            >
              Add Entry
            </button>
            <button
              type="button"
              className="btn btn-default ms-2"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ManualEntryModal;
