import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { formatter } from "../../utils/Formatter";
import { useSelector } from "react-redux";
import { YearStatistic } from "../../store/types";
import { Link } from "react-router-dom";
import { PUBLIC_SALES_SITE } from "../../utils/Params";

const ManageSales: React.FC = () => {
  const [thisYearTotalTags, setThisYearTotalTags] = useState(0);
  const [thisYearTotalDonations, setThisYearTotalDonations] = useState(0);
  const [thisYearTotalTagSales, setThisYearTotalTagSales] = useState(0);
  const [thisYearEmailList, setThisYearEmailList] = useState<string[]>([]);
  const [previousYearsEmailList, setPreviousYearsEmailList] = useState<string[]>([]);
  const [currentTagPrice, setCurrentTagPrice] = useState(0);

  const yearStats = useSelector((state: any) => state.yearStats.data as YearStatistic[]);

  useEffect(() => {
    const thisYearStats = yearStats.find((year) => year.year === new Date().getFullYear());
    const previousYearStats = yearStats.find((year) => year.year === new Date().getFullYear() - 1);
    setThisYearTotalTagSales(thisYearStats?.money_tags || 0);
    setThisYearTotalDonations(thisYearStats?.money_donations || 0);
    setThisYearTotalTags(thisYearStats?.num_tags || 0);
    setThisYearEmailList(thisYearStats?.emails || []);
    setPreviousYearsEmailList(previousYearStats?.emails || []);
  }, [yearStats]);

  const editTagPrice = () => {
    // TODO: Update tag price
  };

  const thisYearTotalSales = thisYearTotalDonations + thisYearTotalTagSales;
  const portionDonations = thisYearTotalDonations / (thisYearTotalSales || 1);
  const portionTagSales = thisYearTotalTagSales / (thisYearTotalSales || 1);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <h2>
            Manage Sales
          </h2>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 mt-3">
        <div className="col">
          <Card className="p-3 h-100">
            <h3>This Year's Stats</h3>
            <h1 className="display-4">
              {formatter.format(thisYearTotalSales)}
            </h1>
            <p className="text-muted mb-0">
              {thisYearTotalTags} Tags Sold
            </p>
            <p className="text-muted mb-0">
              {formatter.format(thisYearTotalDonations)} from donations ({(portionDonations * 100).toFixed(0)}%)
            </p>
            <p className="text-muted mb-0">
              {formatter.format(thisYearTotalTagSales)} from tag sales ({(portionTagSales * 100).toFixed(0)}%)
            </p>
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 h-100">
            <h3>Edit Public Site</h3>
            <p><a href={PUBLIC_SALES_SITE} target="_blank" rel="noreferrer">{PUBLIC_SALES_SITE}</a></p>
            <p className="text-muted mb-0">Edit tag price, pickup day, and other public site content.</p>
            <br />
            <br />
            <Link to="/manage/public-site" className="btn btn-sm btn-outline-secondary">Edit Content</Link>
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 mt-3">
            <h3>This Year's Email List</h3>
            <p className="text-muted">{thisYearEmailList.length} emails</p>
            <textarea
              className="form-control"
              rows={5}
              value={thisYearEmailList.join(", ")}
              readOnly
            />
          </Card>
        </div>
        <div className="col">
          <Card className="p-3 mt-3">
            <h3>Previous Years' Email List</h3>
            <p className="text-muted">{previousYearsEmailList.length} emails</p>
            <textarea
              className="form-control"
              rows={5}
              value={previousYearsEmailList.join(", ")}
              readOnly
            />
          </Card>
        </div>
      </div>
    </div >
  );
};

export default ManageSales;
