import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HouseInfo from "./HouseInfo";
import QuadMap from "./QuadMap";
import { TreeTagQuad } from "../../store/types";
import { useSelector } from "react-redux";

interface QuadPageProps {
  theme: "light" | "dark";
}

const QuadPage: React.FC<QuadPageProps> = ({ theme }) => {
  const { quadId } = useParams();
  const id = parseInt(quadId || "-1");

  const [houseSelected, setHouseSelected] = useState<number>(0);

  const quads = useSelector((state: any) => state.quads.data as TreeTagQuad[]);
  const quad = quads.find((quad) => quad.id === id);

  const assignedQuads = quads.filter((q) => q.assigned_to.includes(id)).map((q) => q.id);

  let overlay = null;
  if (houseSelected) {
    overlay = (
      <HouseInfo
        theme={theme}
        houseId={houseSelected}
        onClose={() => setHouseSelected(0)}
      />
    );
  }

  return (
    <div className="h-100">
      {overlay}
      <div className="h-100" style={{ display: houseSelected ? "none" : "block" }}>
        <QuadMap
          theme={theme}
          assignedQuads={assignedQuads}
          quad={quad}
          onHouseClick={setHouseSelected}
          houseSelected={houseSelected}
        />
      </div>
    </div>
  );
};

export default QuadPage;
